import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';
import { Button, Checkbox, Divider, ListItemText, Menu, MenuItem, MenuList, Box, Typography, FormHelperText, FormControl, IconButton } from '@mui/material';
import { Article, DocumentScanner, FilterList, Summarize } from '@mui/icons-material';
import { useCounter, useLocalStorage } from '@uidotdev/usehooks';
import { useKeyTermsWrapper } from '../../../containers/WidgetWrapper/wrapper';
import { CommentType } from '../../../redux/models/dataModelTypes';

export const DOCUMENT_COMMENT_VALUE: CommentType = "DOCUMENT_HIGHLIGHT";
export const KEY_TERM_COMMENT_VALUE: CommentType = "KEY_TERM";
const ANALYSIS_COMMENT_VALUE = "ANALYSIS";
const REPORT_COMMENT_VALUE = "REPORT";

export type Filter = {
    // Type + value will create a unique filter.
    // The type and value will also determine how the filter is applied.
    type: "COMMENT_TYPE" | "KEY_TERM_GROUP" | "DOCUMENT_KEY_TERM_GROUP" | "DOCUMENT" ;
    value: string;
    label: any;
    enabled: boolean;
    disabledForUse: boolean;
    hasDivider?: boolean;
};

export interface CommentFiltersProps {
    commentTypes: CommentType[];
    onChangeEnabledFilters: (filters: Filter[]) => void;
};

/**
 * CommentFilters is a component that renders a menu of filters for a comments drawer.
 * The filters are divided into three categories: document, key term group, and document.
 * The filters are applied to the comments drawer based on the user's selection.
 * The component also handles saving the user's filter selection to local storage.
 * 
 * @param {{ commentTypes: CommentType[]; onChangeEnabledFilters: (filters: Filter[]) => void; }} props
 * @prop {CommentType} [commentType] The type of comment to filter by.
 * @prop {(filters: Filter[]) => void} onChangeEnabledFilters A callback that will be called when the enabled filters change.
 * 
 * @returns {JSX.Element} The CommentFilters component.
 */
const CommentFilters = ({
    commentTypes,
    onChangeEnabledFilters
}: CommentFiltersProps) => {
    const {
        takerDocumentId,
        takerDocumentUploads
    } = useTakerState();
    const { state } = useKeyTermsWrapper();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [savedFilters, setSavedFilters] = useLocalStorage<Record<string, boolean>>(`${takerDocumentId}-commentDrawerFilters`, {});
    const [availableFilters, setAvailableFilters] = useState<Filter[]>([]);
    const [count, countMutator] = useCounter(0);
    const openMenu = Boolean(anchorEl);

    const targetFileUploadItemId = state.targetFileUploadItemId;

    const targetKeyTermGroupId = useMemo(() => {
        if (!targetFileUploadItemId || !takerDocumentUploads) {
            return;
        }
        return takerDocumentUploads.find(
            tdu => tdu.fileUpload.fileUploadItems.some(
                fui => fui.id === targetFileUploadItemId
            )
        )?.id;
    }, [takerDocumentUploads, targetFileUploadItemId]);
    
    const applySavedFilterOrDefault = useCallback((filter: Partial<Filter>, defaultEnabled: boolean) => {
        const key = `${filter.type}-${filter.value}`;
        filter.enabled = savedFilters[key] ?? defaultEnabled;
        return filter as Filter;
    }, [savedFilters]);

    const isFilterEnabled = (
        filterType: "COMMENT_TYPE" | "KEY_TERM_GROUP" | "DOCUMENT_KEY_TERM_GROUP" | "DOCUMENT", 
        filterValue: string, 
        filters: Filter[]
    ) => {
        for (const filter of filters) {
            if (filter.type === filterType && filter.value === filterValue) {
                return filter.enabled;
            }
        }
        return false;
    };

    // All available filters.
    useEffect(() => {
        const filters: Filter[] = [];

        // Build all of the filters here.
        // Use saved filter values to override defaults.
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: DOCUMENT_COMMENT_VALUE,
                label: <Typography noWrap>Documents</Typography>,
                disabledForUse: false
            }, 
            (!commentTypes.includes(DOCUMENT_COMMENT_VALUE))
        ));

        const documentFiltersEnabled = isFilterEnabled("COMMENT_TYPE", DOCUMENT_COMMENT_VALUE, filters);
        if (documentFiltersEnabled) {
            if (takerDocumentUploads) {
                for (const takerDocumentUpload of takerDocumentUploads) {
                    filters.push(applySavedFilterOrDefault(
                        {
                            type: "DOCUMENT_KEY_TERM_GROUP",
                            value: takerDocumentUpload.id,
                            label: (
                                <Box display="contents" justifyItems="center">
                                    <Summarize fontSize="small"/>
                                    <Typography noWrap>&nbsp;{takerDocumentUpload.name}</Typography>
                                </Box>
                            ),
                            disabledForUse: false
                        },
                        (targetKeyTermGroupId !== takerDocumentUpload.id)
                    ));

                    const tduFilterEnabled = isFilterEnabled("DOCUMENT_KEY_TERM_GROUP", takerDocumentUpload.id, filters);
                    if (documentFiltersEnabled && tduFilterEnabled) {
                        for (const fileItem of takerDocumentUpload.fileUpload.fileUploadItems) {
                            if (fileItem.state !== "ARCHIVED") {
                                filters.push(applySavedFilterOrDefault(
                                    {
                                        type: "DOCUMENT",
                                        value: fileItem.id,
                                        label: (
                                            <Box display="contents" justifyItems="center">
                                                <Article fontSize="small"/>
                                                <Typography noWrap>&nbsp;{fileItem.label}</Typography>
                                            </Box>
                                        ),
                                        disabledForUse: false
                                    }, 
                                    (targetFileUploadItemId !== fileItem.id)
                                ));
                            }
                        }
                    }
                }
            }
        }

        filters[filters.length - 1].hasDivider = true;
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: KEY_TERM_COMMENT_VALUE,
                label: <Typography noWrap>Key Terms</Typography>,
                disabledForUse: false
            },
            (!commentTypes.includes(KEY_TERM_COMMENT_VALUE))
        ));

        const keyTermFiltersEnabled = isFilterEnabled("COMMENT_TYPE", KEY_TERM_COMMENT_VALUE, filters);
        if (keyTermFiltersEnabled) {
            if (takerDocumentUploads) {
                for (const takerDocumentUpload of takerDocumentUploads) {
                    filters.push(applySavedFilterOrDefault(
                        {
                            type: "KEY_TERM_GROUP",
                            value: takerDocumentUpload.id,
                            label: (
                                <Box display="contents" justifyItems="center">
                                    <Summarize fontSize="small"/>
                                    <Typography noWrap>&nbsp;{takerDocumentUpload.name}</Typography>
                                </Box>
                            ),
                            disabledForUse: false
                        },
                        (targetKeyTermGroupId !== takerDocumentUpload.id)
                    ));
                }
            }
        }

        filters[filters.length - 1].hasDivider = true;
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: ANALYSIS_COMMENT_VALUE,
                label: <Typography noWrap>Analysis</Typography>,
                enabled: false,
                disabledForUse: true
            },
            false
        ));
        filters[filters.length - 1].hasDivider = true;
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: REPORT_COMMENT_VALUE,
                label: <Typography noWrap>Report</Typography>,
                enabled: false,
                disabledForUse: true,
            },
            false
        ));
        filters[filters.length - 1].hasDivider = true;
        setAvailableFilters(filters);
    }, [
        commentTypes,
        targetFileUploadItemId,
        targetKeyTermGroupId,
        takerDocumentUploads,
        count
    ]);

    // Relay enabled filters to parent
    useEffect(() => {
        onChangeEnabledFilters(availableFilters.filter(f => f.enabled));
    }, [availableFilters]);

    return (
        <>
            <IconButton
                data-testid="comments-drawer-filters-button"
                sx={{
                    margin: 1
                }}
                size="medium"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                <FilterList fontSize="inherit" />
            </IconButton>
            <Menu
                data-testid="comments-drawer-filters-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: "350px",
                            width: "35ch",
                        },
                    },
                }}
                MenuListProps={{
                    dense: true,
                    disablePadding: true
                }}
            >
                <MenuItem disabled>
                    <ListItemText secondary="* filters are exclusionary"/> 
                </MenuItem>
                {availableFilters.map((f, filterIndex ) => (
                    <MenuItem 
                        data-testid={`comments-drawer-filters-menuitem-${filterIndex}`}
                        disabled={f.disabledForUse} 
                        key={f.label}
                        divider={f.hasDivider}
                    >
                        <Checkbox
                            data-testid={`comments-drawer-filters-checkbox-${filterIndex}`}
                            checked={f.enabled}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                let newSavedFilters = {...savedFilters};
                                newSavedFilters[`${f.type}-${f.value}`] = event.target.checked;
                                setSavedFilters(newSavedFilters);
                                countMutator.increment();
                            }}
                        />
                        {f.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default memo(CommentFilters);