//LIBRARIES----------------------------------------------------------------------------------------
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
//APP_FILES----------------------------------------------------------------------------------------
//APP_FILES_PAGES----------------------------------------------------------------------------------
import Home from "./pages/Home/Home";
import SignIn from "./pages/SignIn/SignIn";
import Taker from "./pages/Taker/Taker";
import UserSettings from "./pages/UserSettings/UserSettings";
import ManageUsers from "./pages/ManageUsers/ManageUsers";
import ManageAppConfigs from "./pages/ManageAppConfigs";
import ManageModels from "./pages/ManageModels";
import ManageOrganizations from "./pages/ManageOrganizations";
import LanguageModel from "./pages/LanguageModel";
import Assistant from "./pages/Assistant";
import Error from "./pages/Error/Error";
import Builder from "./pages/Builder/builder";
//APP_FILES_COMPONENTS-----------------------------------------------------------------------------
import PublicRoutes from "./components/auth/publicRoutes";
import GatedRoutes from "./components/auth/gatedRoutes";
//APP_FILES_REDUX----------------------------------------------------------------------------------
import { useDispatch } from "./redux/reduxUtils/functions";
import { onRefreshPage } from "./redux/actionCreators/authActionCreators";
import { SnackbarProvider } from "notistack";

//APP_FILES_MISC-----------------------------------------------------------------------------------
import theme from "./utils/theme";
import { pageOption } from "./data/pages";
import { PendingJob } from "./components/notifications/PendingJob";
import { GlobalScopedAppDataContainer } from "./containers/GlobalScopedAppData";
import IndexedGuidanceList from "./pages/ManageIndexedGuidance/IndexedGuidanceList";
import IndexedGuidance from "./pages/ManageIndexedGuidance/IndexedGuidance";
import TakerKeyTerms from "./pages/TakerKeyTerms";
import TakerAnalysis from "./pages/TakerAnalysis";
import TakerReport from "./pages/TakerReport";
//END_IMPORTS--------------------------------------------------------------------------------------

declare module 'notistack' {
  interface VariantOverrides {
    myCustomVariant: true;
    pendingJob: {
      takerDocumentUploadAnalysisId?: string;
      takerDocumentAnalysisId?: string;
    }
  }
}

function App() {
  //REDUX
  const dispatch = useDispatch();

  //HOOKS - Persist user on page refresh
  useEffect(() => {
    onRefreshPage(dispatch);
  }, [dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider
        Components={{
          pendingJob: PendingJob,
        }}
        maxSnack={3}
      >
        <GlobalScopedAppDataContainer>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Routes>
                <Route element={<PublicRoutes />}>
                  <Route path={pageOption.SIGN_IN.route} element={<SignIn />} />
                  <Route path="*" element={<Error />} />
                </Route>
                <Route element={<GatedRoutes />}>
                  <Route path={pageOption.USER_SETTINGS.route} element={<UserSettings />} />
                </Route>
                <Route element={<GatedRoutes roles={["BUILDER", "TAKER"]} />}>
                  <Route path={pageOption.HOME.route} element={<Home />} />
                </Route>
                <Route element={<GatedRoutes roles={["BUILDER"]} />}>
                  <Route path={pageOption.MAIN_BUILDER.route} element={<Builder />} />
                </Route>
                <Route element={<GatedRoutes roles={["TAKER"]} />}>
                  <Route 
                    path={pageOption.MAIN_TAKER.route} 
                    element={<Taker/>}
                  >
                    <Route path={pageOption.TAKER_KEY_TERMS.route} element={<TakerKeyTerms/>} />
                    <Route path={pageOption.TAKER_ANALYSIS.route} element={<TakerAnalysis/>} />
                    <Route path={pageOption.TAKER_REPORT.route} element={<TakerReport/>} />
                  </Route>
                </Route>
                <Route element={<GatedRoutes roles={["ADMIN"]} />}>
                  <Route path={pageOption.MANAGE_USERS.route} element={<ManageUsers />} />
                  <Route path={pageOption.MANAGE_ORGANIZATIONS.route} element={<ManageOrganizations />} />
                </Route>
                <Route element={<GatedRoutes roles={["TECHNICAL"]} />}>
                  <Route path={pageOption.MANAGE_MODELS.route} element={<ManageModels />} />
                  <Route path={pageOption.LANGUAGE_MODEL.route} element={<LanguageModel />} />
                  <Route path={pageOption.ASSISTANT.route} element={<Assistant />} />
                </Route>
                <Route element={<GatedRoutes roles={["SUPERADMIN"]} />}>
                  <Route path={pageOption.MANAGE_APP_CONFIGS.route} element={<ManageAppConfigs />} />
                </Route>
                <Route element={<GatedRoutes roles={["INDEXER"]} />}>
                  <Route path={pageOption.INDEXED_GUIDANCE_LIST.route} element={<IndexedGuidanceList />} />
                  <Route path={pageOption.INDEXED_GUIDANCE.route} element={<IndexedGuidance />} />
                </Route>
              </Routes>
            </Router>
          </ThemeProvider>
        </GlobalScopedAppDataContainer>
      </SnackbarProvider>
    </LocalizationProvider>
  );
}

export default App;
