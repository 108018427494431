import { useEffect, useMemo, useState } from "react";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import RowSelectableTable, { HeadCell } from "../../../components/tables/RowSelectableTable";
import { useGetTakerDocumentAnalysisPayloadQuery } from "../../../redux/services/taker";
import { RowData } from "./SummaryRow";
import { useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import EditableSummary from "../../../components/form/EditableSummary";

interface KeyTermSummaryUpdate {
    takerDocumentUploadId: string;
    termName: string;
    summary: string;
};

const headCells: readonly HeadCell[] = [
    {
        id: "key_term",
        disablePadding: true,
        label: "Key Term",
    },
    {
        id: "summary",
        disablePadding: false,
        label: "Summary",
    }
];

interface GeneratedSummaryProps {
    readOnly: boolean;
    selectedRow: RowData;
    setSelectedKts: (kts: KeyTermSummaryUpdate[]) => void;
}

const GeneratedSummary = ({
    readOnly,
    selectedRow,
    setSelectedKts
}: GeneratedSummaryProps) => {
    const [summaries, setSummaries] = useState<KeyTermSummaryUpdate[]>([]);
    const [selectedList, setSelectedList] = useState<number[]>([]);
    const {
        data,
        isSuccess
    } = useGetTakerDocumentAnalysisPayloadQuery(selectedRow.id);
    const { takerDocumentUploads } = useTakerState();
    const { takerDocumentUpload } = useKeyTermGroupState();

    useEffect(() => {
        if (isSuccess) {
            setSummaries(data["summaries_by_key_term"]["keyTermSummaries"]);
        }
    }, [data, isSuccess]);

    const filteredSummaryList = useMemo(() => {
        const groupedLists: Record<string, KeyTermSummaryUpdate[]> = {};
        for (const summary of summaries) {
            if (!groupedLists[summary.takerDocumentUploadId]) {
                groupedLists[summary.takerDocumentUploadId] = [];
            }
            groupedLists[summary.takerDocumentUploadId].push(summary);
        }
        return takerDocumentUpload?.id && groupedLists[takerDocumentUpload.id];

    }, [takerDocumentUploads, summaries]);

    useEffect(() => {
        setSelectedKts((filteredSummaryList || [])
            .filter((s, i) => selectedList.includes(i))
        );
    }, [filteredSummaryList, selectedList]);

    return (
        <RowSelectableTable
            initiallySelected={selectedList}
            disableSelection={readOnly}
            headers={headCells}
            rows={(filteredSummaryList || []).map(kts => ({
                key_term: (
                    <span>
                        {kts.termName}
                    </span>
                ),
                summary: (
                    <EditableSummary
                        data-testid={`key-term-proposed-summary-${kts.termName}`}
                        keyTermName={kts.termName}
                        defaultValue={kts.summary}
                        onChangeValue={(v) => {}}
                        readOnly
                    />
                )
            }))}
            status={new Date(selectedRow.createdAt).toLocaleString()}
            mapFirstCol={() => null}
            onUpdateSelectedRows={(indicies) => setSelectedList(indicies)}
        />
    );
}

export default GeneratedSummary;
