import { ThunkAction } from "redux-thunk";
import { authAction } from "../actions/authActions";
import { RootReducerType, AppDispatch } from "../models/reduxTypes";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut as signOutFirebase,
} from "firebase/auth";
import { auth } from "./../../firebase/config";
import { AuthActionType } from "../models/authTypes";
import axios from 'axios';
import { User } from "../models/dataModelTypes";
import { languageModelApi } from "../services/languageModel";
import { userApi } from "../services/user";
import { takerApi } from "../services/taker";
import { buildersApi } from "../services/builder";
import { assistantApi } from "../services/assistant";
import { organizationApi } from "../services/organization";


const API_ENDPOINT = window.__RUNTIME_CONFIG__.API_ENDPOINT;
const FB_TOKEN_KEY = "fb-token";

// SIGN IN
export const signIn = (
  email: string,
  password: string
): ThunkAction<void, RootReducerType, null, AuthActionType> => {
  return async (dispatch) => {
    try {
      dispatch({ type: authAction.SET_USER_REQUEST });
      const authRes = await signInWithEmailAndPassword(auth, email, password);

      const idToken = await authRes.user.getIdToken();
      localStorage.setItem(FB_TOKEN_KEY, idToken);
      const res = await axios.post(
        `${API_ENDPOINT}/v1/current_user`,
        {
          firebase_id_token: idToken
        },
        { withCredentials: true }
      );

      const userData = res.data as User;
      dispatch({
        type: authAction.SET_USER_SUCESS,
        payload: userData,
      });
    } catch (err: any) {
      dispatch({
        type: authAction.SET_USER_FAILURE,
        payload: err,
      });
    }
  };
};

// SIGN OUT
export const signOut = (): ThunkAction<void, RootReducerType, null, AuthActionType> => {
  return async (dispatch) => {
    try {
      dispatch({ type: authAction.SIGN_OUT_REQUEST });
      await signOutFirebase(auth);

      // Reset states of APIs that serve user data
      dispatch(languageModelApi.util.resetApiState());
      dispatch(userApi.util.resetApiState());
      dispatch(takerApi.util.resetApiState());
      dispatch(buildersApi.util.resetApiState());
      dispatch(assistantApi.util.resetApiState());
      dispatch(organizationApi.util.resetApiState());

      dispatch({ type: authAction.SIGN_OUT_SUCESS });
    } catch (err: any) {
      dispatch({
        type: authAction.SIGN_OUT_FAILURE,
        payload: err,
      });
    }
  };
};

// PERSIST ON PAGE REFRESH
export const onRefreshPage = (dispatch: AppDispatch) => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    if (user) {
      const idToken = await user.getIdToken(/* forceRefresh */ true);
      localStorage.setItem(FB_TOKEN_KEY, idToken);
      try {
        dispatch({ type: authAction.SET_USER_REQUEST });

        const res = await axios.post(
          `${API_ENDPOINT}/v1/current_user`,
          {
            firebase_id_token: idToken
          },
          {
            withCredentials: true
          }
        );

        const userData = res.data as User;
        dispatch({
          type: authAction.SET_USER_SUCESS,
          payload: userData,
        });
      } catch (err: any) {
        dispatch({
          type: authAction.SET_USER_FAILURE,
          payload: err,
        });
      }
    } else {
      dispatch({
        type: authAction.SIGN_OUT_SUCESS,
      });
    }
  });
  unsubscribe();
};
