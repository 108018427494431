import React, { Suspense } from "react";
import { Box } from "@mui/material";
import GeneratedSummaries from "./GeneratedSummaries";
import RectangularSkeleton from "../../components/loading/RectangularSkeleton";

interface KeyTermGroupProps {
    readOnly: boolean;
}

const KeyTermGroupPanelsLazy = React.lazy(() => import("./KeyTermGroupPanels"));

const KeyTermGroup = ({ readOnly }: KeyTermGroupProps) => {
    return (
        <>
            <Box height="100%">
                <Suspense fallback={<RectangularSkeleton/>}>
                    <KeyTermGroupPanelsLazy readOnly={readOnly}/>
                </Suspense>
            </Box>
            <GeneratedSummaries
                data-testid="generate-summaries"
                readOnly={readOnly}
            />
        </>
    );
};
export default KeyTermGroup;
