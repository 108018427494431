import React, { useState } from 'react';
import { useSelector } from "react-redux";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { User } from "../../redux/models/dataModelTypes";
import { UpdateUserModal } from "./updateUserModal";
import { SimpleConfirmModal } from "./../../components/dialog/resusableModals/simpleConfirmModal";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { useDeleteUserMutation, useListUsersQuery } from '../../redux/services/user';
import PagedTable from '../../components/tables/pagedTable';
import { ManageOrganizationMemberships } from './manageOrganizationMemberships';
import { CardMembership } from '@mui/icons-material';
import { CrudButtonGroup } from '../../components/buttons/crudButtonGroup';

export const UserTable = () => {
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(5);

    const [userToEditId, setUserToEditId] = useState<string | null>(null);
    const [userToEditMembershipsId, setUserToEditMembershipsId] = useState<string | null>(null);
    const [userToDelete, setUserToDelete] = useState<User | null>(null);

    const { user } = useSelector((state: RootReducerType) => state.auth);

    const { data: users } = useListUsersQuery({ page, limit });
    const [deleteUser, deleteUserResult] = useDeleteUserMutation();

    return (
        <>
            <PagedTable
                paginatedResponse={users}
                tableSpan={5}
                rowMapper={(u: User) => (
                    <TableRow
                        key={u.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {u.firstName}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                            {u.lastName}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                            {u.email}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                            {u.roles.join(", ")}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                            <CrudButtonGroup
                                buttons={(user && user.id === u.id) ? [
                                    {
                                        icon: <CardMembership />,
                                        handleClick: () => {
                                            setUserToEditMembershipsId(u.id);
                                        }
                                    }
                                ] : [
                                    {
                                        type: "edit",
                                        handleClick: () => {
                                            setUserToEditId(u.id);
                                        }
                                    },
                                    {
                                        type: "delete",
                                        handleClick: () => {
                                            setUserToDelete(u);
                                        }
                                    },
                                    {
                                        icon: <CardMembership />,
                                        handleClick: () => {
                                            setUserToEditMembershipsId(u.id);
                                        }
                                    }
                                ]}
                            />
                        </TableCell>
                        <UpdateUserModal
                            user={u}
                            open={userToEditId === u.id}
                            setOpen={() => setUserToEditId(null)}
                        />
                        <ManageOrganizationMemberships
                            user={u}
                            open={userToEditMembershipsId === u.id}
                            setOpen={() => setUserToEditMembershipsId(null)}
                        />
                    </TableRow>
                )}
                headers={[
                    "First Name",
                    "Last Name",
                    "Email",
                    "Roles",
                    ""
                ]}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
            />
            <SimpleConfirmModal
                mainText="Are you sure you want to delete this user?"
                open={!!userToDelete}
                handleClose={() => setUserToDelete(null)}
                handleSubmit={() => {
                    if (userToDelete) {
                        deleteUser(userToDelete.id);
                        setUserToDelete(null);
                    }
                }}
            />
        </>
    );
}
