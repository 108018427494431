import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, ButtonGroup, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, useTheme } from "@mui/material";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useKeyTermsWrapper, useWidgetState } from "../../containers/WidgetWrapper/wrapper";
import { KeyTermsState } from "../../containers/WidgetWrapper/states";
import { ArrowDropDown, Bolt, Settings } from "@mui/icons-material";
import AssistantIcon from '@mui/icons-material/Assistant';
import GroupManagerModal from "./GroupManagerModal";
import { GenerateSummariesDialog } from "./GenerateSummariesDialog";
import { useSnackbar } from "notistack";
import { useKeyTermGroupState } from "../../containers/TakerDocumentState/KeyTermGroupState";
import { useLocalStorage } from "@uidotdev/usehooks";

const MainToolbar = ({
    readOnly
}: {
    readOnly: boolean;
}) => {
    const {
        taker,
        takerDocumentUploads,
        takerDocumentId
    } = useTakerState();
    const { state, mutateState } = useKeyTermsWrapper()
    const { enqueueSnackbar } = useSnackbar();
    const [selectedTtduId, saveSelectedTtduId] = useLocalStorage<string | null>(`${taker?.id}-ttduId`, null);

    const { groupManagerOpen, panelViewMode } = state;

    const [aiSummaryBtnOpen, setAiSummaryBtnOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const { takerDocumentUpload } = useKeyTermGroupState();
    const groupButtonRef = useRef<HTMLDivElement>(null);
    const [groupsBtnOpen, setGroupsBtnOpen] = React.useState(false);

    useEffect(() => {
        if (!takerDocumentUpload) {
            groupButtonRef?.current?.focus();
        }
    }, [takerDocumentUpload]);

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setAiSummaryBtnOpen(false);
    };

    const handleCloseGroups = (event: Event) => {
        if (
            groupButtonRef.current &&
            groupButtonRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setGroupsBtnOpen(false);
    };

    const selectedPanels = useMemo(() => {
        if (panelViewMode === 0) {
            return [0, 1];
        } else if (panelViewMode === 1) {
            return [0];
        } else if (panelViewMode === 2) {
            return [1];
        }
    }, [panelViewMode]);

    const sortedTakerDocumentUploads = useMemo(() =>
        [...(takerDocumentUploads || [])].sort((a, b) => b.createdAt - a.createdAt),
        [takerDocumentUploads]
    );

    const selectedTtdu = useMemo(
        () => sortedTakerDocumentUploads.find(tdu => tdu.id === selectedTtduId),
        [
            sortedTakerDocumentUploads,
            selectedTtduId
        ]
    );

    const notifySummary = () => {
        enqueueSnackbar("Please create and select a group before using summaries.", {
            key: 'file-item-upload',
            preventDuplicate: true,
            variant: 'info',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    }
    const [openComingSoon, setOpenComingSoon] = useState(false);

    return (
        <Box sx={{ padding: 0.5 }}>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    width: "100%",
                    display: "inline-grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <ButtonGroup
                            variant="outlined"
                            ref={anchorRef}
                            aria-label="Button group with a nested menu"
                            sx={{ marginRight: 1 }}
                        >
                            <Tooltip title={"View All AI Generated Summaries"}>
                                <Button
                                    data-testid="generate-summaries"
                                    onClick={() => {
                                        if (!!takerDocumentUpload?.id) {
                                            mutateState({
                                                showAiSummaries: true
                                            });
                                        } else {
                                            notifySummary();
                                        }
                                    }}
                                    startIcon={<Bolt />}
                                >
                                    Summaries
                                </Button>
                            </Tooltip>
                            <Button
                                data-testid="generate-summaries-dropdown"
                                aria-controls={aiSummaryBtnOpen ? 'split-button-menu' : undefined}
                                aria-expanded={aiSummaryBtnOpen ? 'true' : undefined}
                                aria-haspopup="menu"
                                onClick={() => {
                                    if (!!takerDocumentUpload?.id) {
                                        setAiSummaryBtnOpen((prevOpen) => !prevOpen);
                                    } else {
                                        notifySummary();
                                    }
                                }}
                            >
                                <ArrowDropDown />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            sx={{
                                zIndex: 11,
                            }}
                            open={aiSummaryBtnOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList id="split-button-menu" autoFocusItem>
                                                {["generate new summaries"].map((option, index) => (
                                                    <MenuItem
                                                        data-testid="generate-summaries-dropdown-item"
                                                        key={option}
                                                        disabled={index === 2}
                                                        onClick={(event) => {
                                                            if (index === 0) {
                                                                mutateState({
                                                                    showGenerationDialog: true
                                                                });
                                                            }
                                                            setAiSummaryBtnOpen(false);
                                                        }}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>
                    <Box>
                        <Divider
                            orientation="vertical"
                            sx={{
                                marginLeft: 1,
                                marginRight: 1
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={"Contract Helper Tool"}>
                            <IconButton
                                onClick={() => setOpenComingSoon(true)}
                            >
                                <AssistantIcon />
                            </IconButton>
                        </Tooltip>
                        <Dialog
                            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                            maxWidth="xs"
                            open={openComingSoon}
                        >
                            <DialogTitle>Contract Helper - Coming Soon</DialogTitle>
                            <DialogContent>This feature is currently in development. The Contract Helper is an AI aid that gives the user helpful tips based on the uploaded files. </DialogContent>
                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClick={() => {
                                        setOpenComingSoon(false);
                                    }}
                                >
                                    Okay
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                >
                    <Box color="black">
                        <Tooltip title={"Select a Key Term Group"} placement="top">
                            <ButtonGroup
                                variant="text"
                                color="inherit"
                                ref={groupButtonRef}
                            >
                                <Button
                                    data-testid="key-term-group-button"
                                    disabled={sortedTakerDocumentUploads.length === 0}
                                    onClick={() => {
                                        setGroupsBtnOpen((prevOpen) => !prevOpen);
                                    }}
                                    startIcon={<ArrowDropDown />}
                                >
                                    {!!selectedTtdu ? (
                                        selectedTtdu.name
                                    ) : (
                                        "select a group"
                                    )}
                                </Button>
                            </ButtonGroup>
                        </Tooltip>
                        <Popper
                            data-testid="key-term-group-dropdown-popper"
                            sx={{
                                zIndex: 11,
                            }}
                            open={groupsBtnOpen}
                            anchorEl={groupButtonRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleCloseGroups}>
                                            <MenuList>
                                                {sortedTakerDocumentUploads.map((tdu, index) => (
                                                    <MenuItem
                                                        key={tdu.id}
                                                        data-testid={`key-term-group-dropdown-item-${index}`}
                                                        onClick={(event) => {
                                                            mutateState({
                                                                targetFileUploadItemId: undefined
                                                            });
                                                            saveSelectedTtduId(tdu.id);
                                                            setGroupsBtnOpen(false);
                                                        }}
                                                    >
                                                        {tdu.name}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Tooltip title={"Show Hide Panels"}>
                        <ToggleButtonGroup
                            size="small"
                            color="primary"
                            value={selectedPanels}
                            data-testid="panel-view-mode"
                            onChange={(_, newMode) => {
                                let hasSummaries = newMode.includes(0);
                                let hasDocuments = newMode.includes(1);
                                if (hasSummaries && hasDocuments) {
                                    mutateState({
                                        panelViewMode: 0
                                    });
                                } else if (hasSummaries) {
                                    mutateState({
                                        panelViewMode: 1
                                    });
                                } else if (hasDocuments) {
                                    mutateState({
                                        panelViewMode: 2
                                    });
                                }
                            }}
                        >
                            <ToggleButton
                                data-testid="toggle-key-terms-summaries"
                                value={0}
                            >
                                Key Terms
                            </ToggleButton>
                            <ToggleButton
                                data-testid="toggle-documents"
                                value={1}
                            >
                                Documents
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Tooltip>
                    <Box>
                        <Divider
                            orientation="vertical"
                            sx={{
                                marginLeft: 1,
                                marginRight: 1
                            }}
                        />
                    </Box>
                    <Tooltip title={"Open Key Term Group Manager"} placement="top">
                        <Button
                            variant="outlined"
                            data-testid="group-manager-button"
                            aria-haspopup="menu"
                            onClick={() => mutateState({
                                groupManagerOpen: true
                            })}
                            startIcon={<Settings />}
                        >
                            Groups
                        </Button>
                    </Tooltip>

                </Box>
            </Toolbar>
            <GroupManagerModal
                readOnly={readOnly}
                takerDocumentId={takerDocumentId}
                open={groupManagerOpen}
                setOpen={(o) => mutateState({
                    groupManagerOpen: o
                })}
            />
            <GenerateSummariesDialog />
        </Box>
    );
};

export default MainToolbar;