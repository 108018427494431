import React, { useContext, memo, useState } from "react";
import { KeyTermsState, HighlightsState, ValidWidgetStates } from "./states";
import { useLocalStorage } from "@uidotdev/usehooks";
import { DocumentHighlight } from "../../types/taker/documentkeyterms.generated";

export interface AdditionalAction {
    display: string;
    onClick?: () => void;
    disabled?: boolean;
}

interface WidgetStateHookData {
    getState: <S>() => S;
    mutateState: <S>(s: Partial<S>  | ((ws: S) => Partial<S>)) => void;
}

const Context = React.createContext({});

export function useWidgetState(): WidgetStateHookData {
    return useContext(Context) as WidgetStateHookData;
}

interface WidgetWrapperProps {
    widgetState: ValidWidgetStates | undefined;
    setPartialWidgetState: (partialState: Partial<ValidWidgetStates>) => void;
    children: any;
}

const WidgetWrapper = ({
    setPartialWidgetState,
    widgetState,
    children
}: WidgetWrapperProps) => {
    return (
        <Context.Provider
            value={{
                getState: () => widgetState || {},
                mutateState: (s: Partial<ValidWidgetStates>  | ((ws: ValidWidgetStates) => Partial<ValidWidgetStates>)) => {
                    if (typeof s === "function") {
                        if (widgetState === undefined) {
                            return;
                        }
                        setPartialWidgetState(s(widgetState));
                    } else {
                        setPartialWidgetState(s);
                    }
                },
            }}
        >
            {children}
        </Context.Provider>
    );
}

export default memo(WidgetWrapper);


interface KeyTermsWrapperHookData {
    state: KeyTermsState;
    mutateState: (s: Partial<KeyTermsState>  | ((ws: KeyTermsState) => Partial<KeyTermsState>)) => void;
}

const KeyTermsContext = React.createContext({});

export function useKeyTermsWrapper(): KeyTermsWrapperHookData {
    return useContext(KeyTermsContext) as KeyTermsWrapperHookData;
}

const KeyTermsWrapper = ({
    children
}: {
    children: any
}) => {
    const [groupManagerOpen, setGroupManagerOpen] = useLocalStorage<boolean>("KeyTermsState-GroupManagerOpen", false);
    const [targetFileUploadItemId, setTargetFileUploadItemId] = useLocalStorage<string | null>("KeyTermsState-TargetFileUploadItemId", null);
    const [showAiSummaries, setShowAiSummaries] = useLocalStorage<boolean>("KeyTermsState-ShowAiSummaries", false);
    const [showGenerationDialog, setShowGenerationDialog] = useLocalStorage<boolean>("KeyTermsState-ShowGenerationDialog", false);
    const [showExpandedSummaries, setShowExpandedSummaries] = useLocalStorage<boolean>("KeyTermsState-ShowExpandedSummaries", false);
    const [panelViewMode, setPanelViewMode] = useLocalStorage<0 | 1 | 2>("KeyTermsState-PanelViewMode", 0);
    const [scrollToPage, setScrollToPage] = useLocalStorage<number | undefined>("KeyTermsState-ScrollToPage", undefined);
    const [scrollToElementID, setScrollToElementID] = useLocalStorage<string | undefined>("KeyTermsState-ScrollToElementID", undefined);
    const [activeAnnotationIds, setActiveAnnotationIds] = useLocalStorage<string[]>("KeyTermsState-ActiveAnnotationIds", []);
    const [boxHighlightMode, setBoxHighlightMode] = useLocalStorage<boolean | undefined>("KeyTermsState-BoxHighlightMode", undefined);
    const [currentPageRange, setCurrentPageRange] = useLocalStorage<[number, number] | undefined>("KeyTermsState-CurrentPageRange", undefined);
    const [zoomLevel, setZoomLevel] = useLocalStorage<number | undefined>("KeyTermsState-ZoomLevel", 0.97);
    const [scrollToKeyTermIdentifier, setScrollToKeyTermIdentifier] = useState<string>();

    const state = {
        groupManagerOpen,
        targetFileUploadItemId,
        showAiSummaries,
        showGenerationDialog,
        showExpandedSummaries,
        panelViewMode,
        scrollToPage,
        scrollToElementID,
        activeAnnotationIds,
        boxHighlightMode,
        currentPageRange,
        zoomLevel,
        scrollToKeyTermIdentifier
    } as KeyTermsState;

    const setterMap = {
        groupManagerOpen: setGroupManagerOpen,
        targetFileUploadItemId: setTargetFileUploadItemId,
        showAiSummaries: setShowAiSummaries,
        showGenerationDialog: setShowGenerationDialog,
        showExpandedSummaries: setShowExpandedSummaries,
        panelViewMode: setPanelViewMode,
        scrollToPage: setScrollToPage,
        scrollToElementID: setScrollToElementID,
        activeAnnotationIds: setActiveAnnotationIds,
        boxHighlightMode: setBoxHighlightMode,
        currentPageRange: setCurrentPageRange,
        zoomLevel: setZoomLevel,
        scrollToKeyTermIdentifier: setScrollToKeyTermIdentifier
    };

    const setPartialWidgetState = (partialState: Partial<KeyTermsState>) => {
        for (const [key, value] of Object.entries(partialState)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (setterMap as any)[key](value);
        }
    }

    const mutateState = (s: Partial<KeyTermsState>  | ((ws: KeyTermsState) => Partial<KeyTermsState>)) => {
        if (typeof s === "function") {
            setPartialWidgetState(s(state));
        } else {
            setPartialWidgetState(s);
        }
    };

    return (
        <KeyTermsContext.Provider
            value={{
                state: {
                    groupManagerOpen,
                    targetFileUploadItemId,
                    showAiSummaries,
                    showGenerationDialog,
                    showExpandedSummaries,
                    panelViewMode,
                    scrollToPage,
                    scrollToElementID,
                    activeAnnotationIds,
                    boxHighlightMode,
                    currentPageRange,
                    zoomLevel,
                    scrollToKeyTermIdentifier
                },
                mutateState: mutateState,
            }}
        >
            {children}
        </KeyTermsContext.Provider>
    );
}

const MemoizedKeyTermsWrapper = memo(KeyTermsWrapper);

interface HighlightsWrapperHookData {
    highlightsState: HighlightsState;
    mutateHighlightsState: (s: Partial<HighlightsState>  | ((ws: HighlightsState) => Partial<HighlightsState>)) => void;
}

const HighlightsContext = React.createContext({});

export function useHighlightsWrapper(): HighlightsWrapperHookData {
    return useContext(HighlightsContext) as HighlightsWrapperHookData;
}

const HighlightsWrapper = ({
    children
}: {
    children: any
}) => {
    const [navigateHighlightElementIDs, setNavigateHighlightElementIDs] = useState<DocumentHighlight[] | undefined>(undefined);
    const [stickyNavigateHighlightElementIDs, setStickyNavigateHighlightElementIDs] = useState<boolean | undefined>(undefined);

    const state = {
        navigateHighlightElementIDs,
        stickyNavigateHighlightElementIDs,
    } as HighlightsState;

    const setterMap = {
        navigateHighlightElementIDs: setNavigateHighlightElementIDs,
        stickyNavigateHighlightElementIDs: setStickyNavigateHighlightElementIDs
    };

    const setPartialWidgetState = (partialState: Partial<HighlightsState>) => {
        for (const [key, value] of Object.entries(partialState)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (setterMap as any)[key](value);
        }
    }

    const mutateHighlightsState = (s: Partial<HighlightsState>  | ((ws: HighlightsState) => Partial<HighlightsState>)) => {
        if (typeof s === "function") {
            setPartialWidgetState(s(state));
        } else {
            setPartialWidgetState(s);
        }
    };

    return (
        <HighlightsContext.Provider
            value={{
                highlightsState: {
                    navigateHighlightElementIDs,
                    stickyNavigateHighlightElementIDs
                },
                mutateHighlightsState: mutateHighlightsState
            }}
        >
            {children}
        </HighlightsContext.Provider>
    );
}
const MemoizedHighlightWrapper = memo(HighlightsWrapper)

export {
    MemoizedKeyTermsWrapper as KeyTermsWrapper,
    MemoizedHighlightWrapper as HighlightsWrapper
};