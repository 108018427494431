import { createApi } from '@reduxjs/toolkit/query/react';
import {
    AddContentFilteringAnalysisPayload,
    AddDocPreprocessingAnalysisPayload,
    AddDocQAAnalysisPayload,
    AddExecSummaryAnalysisPayload,
    AddSingleSummaryAnalysisPayload,
    AddMemoGenAnalysisPayload,
    AddTakerDocumentAnalysisPayload,
    UpsertTakerDocumentUploadPayload,
    DeleteTakerDocumentUploadPayload,
    Taker,
    TakerDocument,
    TakerDocumentAccessGrant,
    TakerDocumentAnalysis,
    TakerDocumentReviewComment,
    TakerDocumentState,
    TakerDocumentUpload,
    TakerDocumentUploadAnalysis,
    TakerDocumentSettingsProperty
} from "../models/dataModelTypes";
import { axiosBaseQuery, axiosPostForm } from '../reduxUtils/baseQuery';
import { PaginatedResponse } from '../models/commonTypes';
import { FileUpload } from '../models/dataModelTypes';
import socket from '../../utils/socket';

export const takerApi = createApi({
    reducerPath: 'takerApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['FileUploads', 'Takers', 'TakerDocuments', 'TakerDocumentGrants', 'TakerDocumentStates'],
    endpoints: (build) => ({
        listTakers: build.query<PaginatedResponse<Taker>, { page: number, limit: number, builderId: string }>({
            query: ({ page = 1, limit = 10, builderId }) => ({
                url: `takers?page=${page}&limit=${limit}&builder_id=${builderId}`,
                method: "GET"
            }),
            providesTags: (result) =>
                result
                    ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'Takers', id } as const)),
                        { type: 'Takers', id: 'LIST' },
                    ]
                    :
                    [{ type: 'Takers', id: 'LIST' }],
        }),
        addTaker: build.mutation<Taker, Partial<Taker>>({
            query(body) {
                return {
                    url: `takers`,
                    method: 'POST',
                    data: {
                        name: body.name,
                        description: body.description,
                        builder_id: body.builderId,
                        builder_document_id: body.initialBuilderDocumentId
                    }
                }
            },
            invalidatesTags: [{ type: 'Takers', id: 'LIST' }],
        }),
        getTaker: build.query<Taker, string>({
            query: (id) => ({
                url: `takers/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [{ type: 'Takers', id }],
        }),
        updateTaker: build.mutation<Taker, Partial<Taker>>({
            query(data) {
                const { id, ...body } = data
                return {
                    url: `takers/${id}`,
                    method: 'PUT',
                    data: body
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Takers', id }],
        }),
        deleteTaker: build.mutation<boolean, string>({
            query(id) {
                return {
                    url: `takers/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'Takers', id }],
        }),
        updateTakerDocument: build.mutation<TakerDocument, Partial<TakerDocument>>({
            query(data) {
                const { id, ...body } = data
                return {
                    url: `taker_documents/${id}`,
                    method: 'PUT',
                    data: body
                }
            },
            invalidatesTags: (result, error, { id, takerId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id }
            ],
        }),
        updateTakerDocumentUploadFileUpload: build.mutation<FileUpload, Partial<FileUpload>>({
            query(data) {
                let { id, ...body } = data;
                return {
                    url: `file_uploads/${data.id}`,
                    method: 'PUT',
                    data: {
                        name: body.name,
                        description: body.description
                    }
                }
            },
            invalidatesTags: (result, error, { id, }) => [
                { type: 'FileUploads', id: id }
            ],
        }),
        getTakerDocument: build.query<TakerDocument, string>({
            query(id) {
                return {
                    url: `taker_documents/${id}`,
                    method: "GET"
                };
            },
            async onCacheEntryAdded(
                arg,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                function onTakerDocumentUpdate(td: TakerDocument) {
                    updateCachedData((draft) => {
                        draft.data = td.data;
                        draft.taker = td.taker;
                        draft.takerDocumentUploads = td.takerDocumentUploads;
                        draft.executiveSummaryTakerDocumentAnalyses = td.executiveSummaryTakerDocumentAnalyses;
                        draft.documentQATakerDocumentAnalyses = td.documentQATakerDocumentAnalyses;
                        draft.memoGenerationTakerDocumentAnalyses = td.memoGenerationTakerDocumentAnalyses;
                        draft.takerDocumentAccessGrants = td.takerDocumentAccessGrants;
                        draft.state = td.state;
                        draft.settings = td.settings;
                        draft.updatedAt = td.updatedAt;
                    });
                }

                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded;

                    socket.on('taker-document-update', onTakerDocumentUpdate);

                    // cacheEntryRemoved will resolve when the cache subscription is no longer active
                    await cacheEntryRemoved;
                } catch { }

                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                socket.off('taker-document-update', onTakerDocumentUpdate);
            },
            providesTags: (result, error, id) => [{ type: 'TakerDocuments', id }],
        }),
        getTakerDocumentStateV2: build.query<any, string>({
            query(id) {
                return {
                    url: `taker_documents/${id}/state_v2`,
                    method: "GET"
                };
            },
            async onCacheEntryAdded(
                arg,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                function onTakerDocumentStateUpdate(takerState: any) {
                    updateCachedData((draft) => {
                        Object.assign(draft, takerState);
                    });
                }

                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded;

                    socket.on('taker-document-state-update-v2', onTakerDocumentStateUpdate);

                    // cacheEntryRemoved will resolve when the cache subscription is no longer active
                    await cacheEntryRemoved;
                } catch { }

                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                socket.off('taker-document-state-update-v2', onTakerDocumentStateUpdate);
            },
            providesTags: (result, error, id) => [{ type: 'TakerDocumentStates', id }],
        }),
        addTakerDocumentUpload: build.mutation<TakerDocumentUpload, Partial<UpsertTakerDocumentUploadPayload>>({
            query(body) {
                const {
                    takerDocumentId,
                    name,
                    description
                } = body;
                return {
                    url: `taker_documents/${takerDocumentId}/upload`,
                    method: 'POST',
                    data: {
                        name,
                        description
                    }
                }
            },
            invalidatesTags: (result, error, { takerDocumentId, takerId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        updateTakerDocumentUpload: build.mutation<TakerDocumentUpload, Partial<UpsertTakerDocumentUploadPayload>>({
            query(body) {
                const {
                    id,
                    takerDocumentId,
                    name,
                    description
                } = body;
                return {
                    url: `taker_documents/${takerDocumentId}/upload/${id}`,
                    method: 'PUT',
                    data: {
                        name,
                        description
                    }
                }
            },
            invalidatesTags: (result, error, { takerDocumentId, takerId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        deleteTakerDocumentUpload: build.mutation<boolean, DeleteTakerDocumentUploadPayload>({
            query(payload) {
                const {
                    id,
                    takerDocumentId
                } = payload;
                return {
                    url: `taker_documents/${takerDocumentId}/upload/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, { takerId, takerDocumentId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        addExecSummaryAnalysis: build.mutation<TakerDocumentAnalysis, Partial<AddExecSummaryAnalysisPayload>>({
            query(data) {
                const { takerDocumentId, takerDocumentUploads, disableLlm } = data;
                return {
                    url: `create_exec_summary_analysis`,
                    method: 'POST',
                    data: {
                        taker_document_id: takerDocumentId,
                        taker_document_upload_ids: (takerDocumentUploads || []).map(tdu => tdu.id),
                        configuration: {
                            disable_llm_capabilities: disableLlm
                        }
                    }
                }
            },
            invalidatesTags: (result, error, { takerId, takerDocumentId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        addSingleSummaryAnalysis: build.mutation<TakerDocumentAnalysis, Partial<AddSingleSummaryAnalysisPayload>>({
            query(data) {
                const { takerDocumentId, takerDocumentUploadId, summaryType, keyTermId, disableLlm, existingSummary } = data;
                return {
                    url: `create_single_summary_analysis`,
                    method: 'POST',
                    data: {
                        taker_document_id: takerDocumentId,
                        key_term_id: keyTermId,
                        taker_document_upload_id: takerDocumentUploadId,
                        configuration: {
                            disable_llm_capabilities: disableLlm
                        },
                        summary_type: summaryType,
                        existing_summary: existingSummary
                    }
                }
            },
            invalidatesTags: (result, error, { takerId, takerDocumentId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        addContentFilteringAnalysis: build.mutation<TakerDocumentUploadAnalysis, Partial<AddContentFilteringAnalysisPayload>>({
            query(data) {
                const {
                    takerDocumentId,
                    takerDocumentUpload,
                    enableKeyTermTagging,
                    fileItemId,
                    enableContentFilteringV2
                } = data;
                return {
                    url: `create_content_filtering_analysis`,
                    method: 'POST',
                    data: {
                        taker_document_id: takerDocumentId,
                        taker_document_upload_id: takerDocumentUpload && takerDocumentUpload.id,
                        configuration: {
                            enable_key_term_tagging: enableKeyTermTagging,
                            file_item_id: fileItemId
                        },
                        use_v2: enableContentFilteringV2
                    }
                }
            },
            invalidatesTags: (result, error, { takerId, takerDocumentId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        addDocPreprocessingAnalysis: build.mutation<TakerDocumentUploadAnalysis, Partial<AddDocPreprocessingAnalysisPayload>>({
            query(data) {
                const { takerDocumentId, takerDocumentUpload } = data
                return {
                    url: `create_doc_preprocessing_analysis`,
                    method: 'POST',
                    data: {
                        taker_document_id: takerDocumentId,
                        taker_document_upload_id: takerDocumentUpload && takerDocumentUpload.id
                    }
                }
            },
            invalidatesTags: (result, error, { takerId, takerDocumentId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        addDocQAAnalysis: build.mutation<TakerDocumentAnalysis, Partial<AddDocQAAnalysisPayload>>({
            query(data) {
                const {
                    takerDocumentId,
                    lockedQuestions,
                    stoppingQuestionQid
                } = data
                return {
                    url: `create_qa_analysis`,
                    method: 'POST',
                    data: {
                        taker_document_id: takerDocumentId,
                        configuration: {
                            locked_questions: lockedQuestions,
                            stopping_question_qid: stoppingQuestionQid
                        }
                    },
                }
            },
            invalidatesTags: (result, error, { takerId, takerDocumentId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        addMemoGenAnalysis: build.mutation<TakerDocumentAnalysis, Partial<AddMemoGenAnalysisPayload>>({
            query(data) {
                const {
                    takerDocumentId,
                    previousAnalysisId,
                    disableLlm
                } = data;
                const configuration: Record<string, any> = {
                    disable_llm_capabilities: disableLlm
                };
                if (previousAnalysisId !== undefined) {
                    configuration['previous_analysis_id'] = previousAnalysisId;
                }
                return {
                    url: `create_memo_gen_analysis`,
                    method: 'POST',
                    data: {
                        taker_document_id: takerDocumentId,
                        configuration
                    },
                }
            },
            invalidatesTags: (result, error, { takerId, takerDocumentId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        addTakerDocumentAnalysis: build.mutation<TakerDocumentAnalysis, Partial<AddTakerDocumentAnalysisPayload>>({
            query(data) {
                const { type, takerDocumentId } = data
                return {
                    url: `taker_document_analyses`,
                    method: 'POST',
                    data: {
                        "type": type,
                        "taker_document_id": takerDocumentId
                    }
                }
            },
            invalidatesTags: (result, error, { takerId, takerDocumentId }) => [
                { type: 'Takers', id: takerId },
                { type: 'TakerDocuments', id: takerDocumentId }
            ],
        }),
        getTakerDocumentAnalysisPayload: build.query<any, string>({
            query: (id) => ({
                url: `taker_document_analysis/${id}/payload`,
                method: "GET"
            })
        }),
        getTakerDocumentUploadAnalysisPayload: build.query<any, string>({
            query: (id) => ({
                url: `taker_document_upload_analysis/${id}/payload`,
                method: "GET"
            })
        }),
        getTakerDocumentUploadAnalysisSummary: build.query<any, string>({
            query: (id) => ({
                url: `taker_document_upload_analysis/${id}/payload`,
                method: "GET"
            })
        }),
        addTakerDocumentAccessGrant: build.mutation<TakerDocumentAccessGrant, Partial<TakerDocumentAccessGrant> & { takerId: string }>({
            query(data) {
                const {
                    type,
                    userId,
                    takerDocumentId,
                    organizationId
                } = data;
                return {
                    url: `taker_document_access_grants`,
                    method: 'POST',
                    data: {
                        type,
                        user_id: userId,
                        taker_document_id: takerDocumentId,
                        organization_id: organizationId
                    }
                }
            },
            invalidatesTags: (result, error, { id, takerId, takerDocumentId }) => [
                { type: 'TakerDocumentGrants', id },
                { type: 'TakerDocuments', id: takerDocumentId },
                { type: 'Takers', id: takerId }
            ],
        }),
        deleteTakerDocumentAccessGrant: build.mutation<TakerDocumentAccessGrant, Partial<TakerDocumentAccessGrant> & { takerId: string }>({
            query(data) {
                const { id } = data;
                return {
                    url: `taker_document_access_grants/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { id, takerId, takerDocumentId }) => [
                { type: 'TakerDocumentGrants', id },
                { type: 'TakerDocuments', id: takerDocumentId },
                { type: 'Takers', id: takerId }
            ],
        }),
        listUserTakerDocumentAccessGrants: build.query<PaginatedResponse<TakerDocumentAccessGrant>, { page: number, limit: number, selectedOrgId: string }>({
            query: ({ page = 1, limit = 10, selectedOrgId }) => ({
                url: `taker_document_access_grants?page=${page}&limit=${limit}&organization_id=${selectedOrgId}`,
                method: "GET"
            }),
            providesTags: (result) =>
                result
                    ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'TakerDocumentGrants', id } as const)),
                        { type: 'TakerDocumentGrants', id: 'LIST' },
                    ]
                    :
                    [{ type: 'TakerDocumentGrants', id: 'LIST' }],
        }),
        addTakerDocumentReviewComment: build.mutation<TakerDocumentReviewComment, Partial<TakerDocumentReviewComment> & { takerDocumentId: string }>({
            query: ({ takerDocumentId, commentLexical, commentText, commentType, commentMetadata }) => ({
                url: `/taker_documents/${takerDocumentId}/review_comment`,
                method: "POST",
                data: {
                    comment_type: commentType,
                    comment_lexical: commentLexical,
                    comment_text: commentText,
                    comment_metadata: commentMetadata
                }
            }),
            invalidatesTags: (result, error, { takerDocumentId }) => [
                { type: 'TakerDocuments', id: takerDocumentId },
            ]
        }),
        updateTakerDocumentReviewComment: build.mutation<TakerDocumentReviewComment, Partial<TakerDocumentReviewComment> & { takerDocumentId: string }>({
            query: ({ id, takerDocumentId, commentLexical, commentText }) => ({
                url: `/taker_documents/${takerDocumentId}/review_comment/${id}`,
                method: "PUT",
                data: {
                    comment_lexical: commentLexical,
                    comment_text: commentText
                }
            }),
            invalidatesTags: (result, error, { takerDocumentId }) => [
                { type: 'TakerDocuments', id: takerDocumentId },
            ]
        }),
        deleteTakerDocumentReviewComment: build.mutation<TakerDocumentReviewComment, Partial<TakerDocumentReviewComment> & { takerDocumentId: string }>({
            query: ({ takerDocumentId, id }) => ({
                url: `/taker_documents/${takerDocumentId}/review_comment/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, { takerDocumentId }) => [
                { type: 'TakerDocuments', id: takerDocumentId },
            ]
        }),
        updateTakerDocumentState: build.mutation<TakerDocumentState, Partial<TakerDocumentState>>({
            query: ({ id, takerDocumentId, reviewState }) => ({
                url: `/taker_documents/${takerDocumentId}/state/${id}`,
                method: "PUT",
                data: {
                    review_state: reviewState
                }
            }),
            invalidatesTags: (result, error, { takerDocumentId }) => [
                { type: 'TakerDocuments', id: takerDocumentId },
            ]
        }),
        updateTakerDocumentAnalysis: build.mutation<TakerDocumentAnalysis, Partial<TakerDocumentAnalysis>>({
            query: ({ id, data }) => ({
                url: `/taker_document_analysis/${id}`,
                method: "PUT",
                data: {
                    data: data
                }
            }),
            invalidatesTags: (result, error, { takerDocumentId }) => [
                { type: 'TakerDocuments', id: takerDocumentId },
            ]
        }),
        updateTakerDocumentUploadAnalysis: build.mutation<TakerDocumentUploadAnalysis, Partial<TakerDocumentUploadAnalysis> & { takerDocumentId: string; }>({
            query: ({ id, data }) => ({
                url: `/taker_document_upload_analysis/${id}`,
                method: "PUT",
                data: {
                    data: data
                }
            }),
            invalidatesTags: (result, error, { takerDocumentId }) => [
                { type: 'TakerDocuments', id: takerDocumentId },
            ]
        }),
        addTakerDocumentSettingsProperty: build.mutation<TakerDocumentSettingsProperty, Partial<TakerDocumentSettingsProperty> & { takerDocumentId: string }>({
            query: ({ takerDocumentId, takerDocumentSettingsId, type, value }) => ({
                url: `/taker_documents/${takerDocumentId}/settings/${takerDocumentSettingsId}/properties`,
                method: "POST",
                data: {
                    type,
                    value
                }
            }),
            invalidatesTags: (result, error, { takerDocumentId }) => [
                { type: 'TakerDocuments', id: takerDocumentId },
            ]
        }),
        updateTakerDocumentSettingsProperty: build.mutation<TakerDocumentSettingsProperty, Partial<TakerDocumentSettingsProperty> & { takerDocumentId: string; }>({
            query: ({ id, takerDocumentId, takerDocumentSettingsId, type, value }) => ({
                url: `/taker_documents/${takerDocumentId}/settings/${takerDocumentSettingsId}/properties/${id}`,
                method: "PUT",
                data: {
                    value
                }
            }),
            invalidatesTags: (result, error, { takerDocumentId }) => [
                { type: 'TakerDocuments', id: takerDocumentId },
            ]
        })
    })
})

export const takerImportApi = createApi({
    reducerPath: 'takerImportApi',
    baseQuery: axiosPostForm(),
    tagTypes: [],
    endpoints: (build) => ({
        bundleImport: build.mutation<any, { takerDocumentId: string; formData: any }>({
            query(data) {
                let {
                    takerDocumentId,
                    formData,
                } = data;
                return {
                    url: `taker_documents/${takerDocumentId}/bundle_import`,
                    formData
                }
            }
        })
    })
});

export const {
    useListTakersQuery,
    useAddTakerMutation,
    useGetTakerQuery,
    useUpdateTakerMutation,
    useDeleteTakerMutation,
    useGetTakerDocumentQuery,
    useUpdateTakerDocumentMutation,
    useAddTakerDocumentUploadMutation,
    useUpdateTakerDocumentUploadMutation,
    useDeleteTakerDocumentUploadMutation,
    useGetTakerDocumentStateV2Query,
    useAddContentFilteringAnalysisMutation,
    useAddDocPreprocessingAnalysisMutation,
    useAddDocQAAnalysisMutation,
    useAddExecSummaryAnalysisMutation,
    useAddSingleSummaryAnalysisMutation,
    useAddMemoGenAnalysisMutation,
    useUpdateTakerDocumentUploadFileUploadMutation,
    useGetTakerDocumentAnalysisPayloadQuery,
    useGetTakerDocumentUploadAnalysisPayloadQuery,
    useGetTakerDocumentUploadAnalysisSummaryQuery,
    useAddTakerDocumentAccessGrantMutation,
    useDeleteTakerDocumentAccessGrantMutation,
    useListUserTakerDocumentAccessGrantsQuery,
    useAddTakerDocumentReviewCommentMutation,
    useUpdateTakerDocumentReviewCommentMutation,
    useDeleteTakerDocumentReviewCommentMutation,
    useUpdateTakerDocumentStateMutation,
    useUpdateTakerDocumentAnalysisMutation,
    useUpdateTakerDocumentUploadAnalysisMutation,
    useAddTakerDocumentSettingsPropertyMutation,
    useUpdateTakerDocumentSettingsPropertyMutation
} = takerApi;

export const {
    useBundleImportMutation
} = takerImportApi;