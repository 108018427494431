import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import {
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    TextField
} from "@mui/material/";
import { FileUploadWithNameRhf2 } from "../../components/form/reactHookForm/fileUploadWithNameRhf";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useAddTakerDocumentUploadMutation, useUpdateTakerDocumentUploadMutation } from "../../redux/services/taker";
import {
    CircularProgress,
    Table
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import {
    TakerDocumentUpload
} from "../../redux/models/dataModelTypes";
import { useDeleteTakerDocumentUploadMutation } from '../../redux/services/taker';
import { Add, Cancel, Check, Delete, Error, KeyboardArrowDown, KeyboardArrowRight, OpenInNew, Save } from '@mui/icons-material';
import { CrudButtonGroup } from '../../components/buttons/crudButtonGroup';
import EditableText from "../../components/form/EditableText";
import {
    useAddFileUploadItemContentMutation,
    useAddFileUploadItemMutation,
    useDeleteFileUploadItemMutation,
    useUpdateFileItemMutation
} from "../../redux/services/fileUpload";
import { useSnackbar } from "notistack";
import { useLocalStorage } from "@uidotdev/usehooks";

const FILE_TYPE_MAP: Record<string, string> = {
    "application/pdf": "PDF"
};

interface GroupManagerRowProps {
    takerDocumentUpload: TakerDocumentUpload;
    readOnly: boolean;
    file: File | null;
    setFile: (f: File | null) => void;
    open: boolean;
    setOpen: (o: boolean) => void;
    index: number;
    takerDocumentUploads: Array<TakerDocumentUpload> | undefined;
}

export const GroupManagerRow = ({
    takerDocumentUpload,
    readOnly,
    file,
    setFile,
    open,
    setOpen,
    index,
    takerDocumentUploads
}: GroupManagerRowProps) => {
    const {
        taker,
        createContentFilteringAnalyses,
        takerDocumentId,
        refetchTakerDocument
    } = useTakerState();

    const { enqueueSnackbar } = useSnackbar();
    const [hiddenFileItemIds, setHiddenFileItemIds] = useState<string[]>([]);

    const localStorageGetterSetter = useLocalStorage<string | null>(`${taker?.id}-ttduId`, null);
    const selectedTtduId = localStorageGetterSetter && localStorageGetterSetter[0];
    const saveSelectedTtduId = localStorageGetterSetter && localStorageGetterSetter[1];

    const [deleteTakerDocumentUpload, deleteTakerDocumentUploadResult] = useDeleteTakerDocumentUploadMutation();
    const [updateTakerDocumentUpload, updateTakerDocumentUploadResult] = useUpdateTakerDocumentUploadMutation();
    const [addFileUploadItemContent, addFileUploadItemContentRes] = useAddFileUploadItemContentMutation();
    const [addFileUploadItem, addFileUploadItemRes] = useAddFileUploadItemMutation();
    const [updateFileItem, updateFileItemRes] = useUpdateFileItemMutation();
    const [deleteFileUploadItem, deleteFileUploadItemRes] = useDeleteFileUploadItemMutation();

    useEffect(() => {
        if (deleteFileUploadItemRes.isSuccess) {
            refetchTakerDocument();
        }
    }, [deleteFileUploadItemRes]);

    useEffect(() => {
        if (addFileUploadItemContentRes.isSuccess) {
            createContentFilteringAnalyses(takerDocumentUpload, addFileUploadItemContentRes.data.id);
            enqueueSnackbar("File content has been uploaded", {
                key: 'file-item-upload',
                preventDuplicate: true,
                variant: 'info',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    }, [addFileUploadItemContentRes]);

    useEffect(() => {
        if (deleteTakerDocumentUploadResult.isSuccess && deleteTakerDocumentUploadResult.originalArgs) {
            if (selectedTtduId === deleteTakerDocumentUploadResult.originalArgs.id) {
                saveSelectedTtduId(null);
            }
            enqueueSnackbar("Key Term Group Deleted", {
                key: 'key-term-group-deletion',
                preventDuplicate: true,
                variant: 'info',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    }, [deleteTakerDocumentUploadResult.isSuccess])

    useEffect(() => {
        if (addFileUploadItemRes.isSuccess) {
            enqueueSnackbar("File is being uploaded", {
                key: 'file-item-upload',
                preventDuplicate: true,
                variant: 'info',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });

            let fileUploadItem = addFileUploadItemRes.data
            if (file) {
                const formData = new FormData();
                formData.append(
                    "file",
                    file,
                    fileUploadItem.label
                );
                addFileUploadItemContent({
                    id: fileUploadItem.id,
                    fileUploadId: takerDocumentUpload.fileUpload.id,
                    formData,
                });
                setFile(null);
            }
        }
    }, [addFileUploadItemRes]);

    useEffect(() => {
        if (updateFileItemRes.isSuccess) {
            refetchTakerDocument();
        }
    }, [updateFileItemRes]);

    const createFileUploadItem = () => {
        if (file) {
            addFileUploadItem({
                label: file.name,
                type: FILE_TYPE_MAP[file.type],
                fileUploadId: takerDocumentUpload.fileUploadId,
            });
        }
    };

    const isFileAnalysisPending = (targetFileItemId: string) => {
        for (const tdua of takerDocumentUpload.contentFilteringTakerDocumentUploadAnalyses) {
            let fileItemId = tdua.data['file_item_id'];
            if (fileItemId === targetFileItemId) {
                return tdua && (tdua.state === "PENDING_GENERATION");
            }
        }
    };

    const isFileAnalysisComplete = (targetFileItemId: string) => {
        for (const tdua of takerDocumentUpload.contentFilteringTakerDocumentUploadAnalyses) {
            let fileItemId = tdua.data['file_item_id'];
            if (fileItemId === targetFileItemId) {
                return tdua
                    && (
                        tdua.state === "PENDING_REVIEW" ||
                        tdua.state === "APPROVED"
                    );
            }
        }
    };

    const navigateToAsset = async (itemId: string) => {
        const url = `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/v1/file_uploads/${takerDocumentUpload.fileUpload.id}/items/${itemId}/content`;
        window.open(url, "_blank")
    };

    const sortedFileUploadItems = useMemo(() =>
        [...takerDocumentUpload.fileUpload.fileUploadItems]
            .sort((a, b) => b.createdAt - a.createdAt)
            .filter(fui => fui.state !== "ARCHIVED"),
        [
            takerDocumentUpload,
            takerDocumentUpload.fileUpload.fileUploadItems
        ]
    );


    const [itemsToDisplay, setItemsToDisplay] = useState([...sortedFileUploadItems]);

    useEffect(() => {
        setItemsToDisplay([...sortedFileUploadItems]);
    }, [sortedFileUploadItems]);

    const [editedItemId, setEditedItemId] = useState<string>("");

    const handleCellClick = (id: string) => {
        setEditedItemId(id);
    };

    const updateLocalItemLabel = (id: string, newLabel: string) => {
        let prevItems = [...itemsToDisplay];
        let newItems = prevItems.map((fui) => {
            if (fui.id === id) {
                return Object.assign({}, fui, { label: newLabel })
            } else {
                return fui;
            }
        });
        setItemsToDisplay(newItems);
    }

    const onNameChange = useCallback((v: string) => {
        if(takerDocumentUpload.name === v) {
            return true;
        }

        const groupNameExists = takerDocumentUploads?.some(tdu => tdu.name === v);
        if (groupNameExists) {
            enqueueSnackbar(`Group name "${v}" already exists`, {
                variant: "error"
            });
            return false;
        } else if (taker) {
            updateTakerDocumentUpload({
                id: takerDocumentUpload.id,
                takerId: taker.id,
                takerDocumentId: takerDocumentUpload.takerDocumentId,
                name: v,
                description: takerDocumentUpload.description
            });
            return true;
        }
        return false;
    }, [takerDocumentUploads, takerDocumentUpload, taker, enqueueSnackbar, updateTakerDocumentUpload]);

    return (
        <React.Fragment>
            <TableRow
                data-testid={`key-term-group-row-${index}`}
                key={takerDocumentUpload.id}
                sx={{ '& > *': { borderBottom: 'unset' } }}
            >
                <TableCell>
                    <IconButton
                        data-testid={`key-term-group-row-toggle-${index}`}
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {new Date(takerDocumentUpload.createdAt).toLocaleString()}
                </TableCell>
                <TableCell data-testid={`key-term-group-row-name-${index}`}>
                    <EditableText
                        defaultValue={takerDocumentUpload.name}
                        placeholder="Add a group name"
                        onChangeValue={onNameChange}
                        readOnly={readOnly}
                    />
                </TableCell>
                <TableCell data-testid={`key-term-group-row-description-${index}`}>
                    <EditableText
                        defaultValue={takerDocumentUpload.description}
                        placeholder="Add a group description"
                        onChangeValue={(v) => {
                            if (taker) {
                                updateTakerDocumentUpload({
                                    id: takerDocumentUpload.id,
                                    takerId: taker.id,
                                    takerDocumentId: takerDocumentUpload.takerDocumentId,
                                    name: takerDocumentUpload.name,
                                    description: v
                                });
                                return true;
                            }
                            return false;
                        }}
                        readOnly={readOnly}
                        multiline
                    />
                </TableCell>
                <TableCell>
                    <CrudButtonGroup
                        buttons={[
                            {
                                icon: <Delete />,
                                disabled: readOnly,
                                type: "delete",
                                handleClick: () => {
                                    if (taker) {
                                        deleteTakerDocumentUpload({
                                            id: takerDocumentUpload.id,
                                            takerId: taker.id,
                                            takerDocumentId: takerDocumentId
                                        });
                                    }
                                }
                            }
                        ]}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: open ? "20px" : 0,
                        paddingTop: 0
                    }}
                    colSpan={5}
                >
                    <Collapse in={open}>
                        {(itemsToDisplay.length > 0) && (
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                variant="head"
                                                sx={{ fontWeight: "bolder" }}
                                            >
                                                Name
                                            </TableCell>
                                            <TableCell
                                                variant="head"
                                                sx={{ fontWeight: "bolder" }}
                                            >
                                                Type
                                            </TableCell>
                                            <TableCell
                                                variant="head"
                                                sx={{ fontWeight: "bolder" }}
                                            >
                                                Processed
                                            </TableCell>
                                            <TableCell
                                                variant="head"
                                                sx={{ fontWeight: "bolder" }}
                                            >
                                                Uploaded
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {itemsToDisplay.map((fui, i) => (
                                            <TableRow
                                                data-testid={`key-term-file-item-row-${index}-${i}`}
                                                key={fui.id}
                                            >
                                                <TableCell onClick={() => handleCellClick(fui.id)}>
                                                    {editedItemId === fui.id ? (
                                                        <div key={fui.id} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <TextField
                                                                size="small"
                                                                value={fui.label}
                                                                sx={{ maxWidth: `40vw` }}
                                                                onChange={(e) => {
                                                                    updateLocalItemLabel(fui.id, e.target.value);
                                                                }}
                                                            />
                                                            <IconButton
                                                                data-testid="save-name-button"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    updateFileItem({
                                                                        id: fui.id,
                                                                        fileUploadId: fui.fileUploadId,
                                                                        label: fui.label
                                                                    });
                                                                    setEditedItemId("");
                                                                }}
                                                                color="info"
                                                            >
                                                                <Save />
                                                            </IconButton>
                                                            <IconButton
                                                                data-testid="cancel-name-button"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setEditedItemId("");
                                                                }}
                                                                color="error"
                                                            >
                                                                <Cancel />
                                                            </IconButton>
                                                        </div>
                                                    ) : (
                                                        fui.label
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {fui.type}
                                                </TableCell>
                                                <TableCell>
                                                    {isFileAnalysisPending(fui.id) ? (
                                                        <CircularProgress size={14} />
                                                    ) : (
                                                        isFileAnalysisComplete(fui.id) ? (
                                                            <Check />
                                                        ) : (
                                                            <Error />
                                                        )
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(fui.createdAt).toLocaleString()}
                                                </TableCell>
                                                <TableCell>
                                                    <span style={{ display: "flex" }}>
                                                        <IconButton
                                                            disabled={hiddenFileItemIds.includes(fui.id)}
                                                            data-testid={`key-term-file-item-delete-${index}-${i}`}
                                                            onClick={() => {
                                                                deleteFileUploadItem({
                                                                    id: fui.id,
                                                                    fileUploadId: fui.fileUploadId
                                                                });
                                                                setHiddenFileItemIds([...hiddenFileItemIds, fui.id]);
                                                            }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                        <IconButton
                                                            disabled={hiddenFileItemIds.includes(fui.id)}
                                                            data-testid={`key-term-group-row-file-item-open-${index}-${i}`}
                                                            onClick={() => navigateToAsset(fui.id)}
                                                        >
                                                            <OpenInNew />
                                                        </IconButton>
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Box
                            sx={{
                                paddingTop: "20px"
                            }}
                            display="inline-block"
                        >
                            {open && (
                                <FileUploadWithNameRhf2
                                    key={takerDocumentUpload.id}
                                    label="Source File"
                                    onChangeFile={(f) => setFile(f)}
                                    helperText="Upload the source document for highlighting."
                                    defaultFile={file}
                                    accept="application/pdf"
                                />
                            )}
                            {file && (
                                <Button
                                    data-testid={`key-term-group-row-upload-${index}`}
                                    sx={{ marginTop: "10px" }}
                                    variant="contained"
                                    onClick={() => createFileUploadItem()}
                                >
                                    Upload To Group
                                </Button>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );

};

interface Props {
    takerDocumentId: string;
    open: boolean;
    setOpen: (o: boolean) => void;
    readOnly: boolean;
}

export default ({
    takerDocumentId,
    open,
    setOpen,
    readOnly
}: Props) => {
    const {
        taker,
        takerDocumentUploads,

    } = useTakerState();
    const [files, setFiles] = useState<Record<string, File | null>>({});
    const [openRow, setRowOpen] = useState<Record<string, boolean>>({});

    const [addTakerDocumentUpload, addTakerDocumentUploadResult] = useAddTakerDocumentUploadMutation();

    const sortedTakerDocumentUploads = useMemo(() =>
        [...(takerDocumentUploads || [])].sort((a, b) => b.createdAt - a.createdAt),
        [takerDocumentUploads]
    );

    const uniqueNewGroupName = () => {
        const baseNewGroupName: string = "new group"
        let numNew: number = 1;
        while (takerDocumentUploads?.some(tdu => tdu.name === `${baseNewGroupName} ${numNew}`)) {
            numNew += 1;
        }
        return `${baseNewGroupName} ${numNew}`;
    }

    return (
        <SimpleModalWrapper
            headerText="Key Term Groups"
            open={open}
            handleClose={() => setOpen(false)}
            maxWidth='md'
        >
            <Box
                sx={{
                    width: "100%",
                    padding: 1
                }}
            >
                <Grid
                    container
                    sx={{
                        width: "100%"
                    }}
                    justifyContent="end"
                >
                    <Grid item>
                        <Button
                            startIcon={<Add />}
                            data-testid="add-group-button"
                            onClick={() => {
                                addTakerDocumentUpload({
                                    takerId: taker && taker.id,
                                    takerDocumentId: takerDocumentId,
                                    name: uniqueNewGroupName(),
                                    description: "",
                                });
                            }}
                            variant="contained"
                        >
                            Add Group
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell
                                variant="head"
                                sx={{ fontWeight: "bolder" }}
                            >
                                Created
                            </TableCell>
                            <TableCell
                                variant="head"
                                sx={{ fontWeight: "bolder" }}
                            >
                                Group Name
                            </TableCell>
                            <TableCell
                                variant="head"
                                sx={{ fontWeight: "bolder" }}
                            >
                                Description
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedTakerDocumentUploads && sortedTakerDocumentUploads.map((takerDocumentUpload: TakerDocumentUpload, i: number) => (
                            <GroupManagerRow
                                takerDocumentUpload={takerDocumentUpload}
                                readOnly={readOnly}
                                file={!!files[takerDocumentUpload.id] ? files[takerDocumentUpload.id] : null}
                                setFile={(f) => {
                                    const newFiles = { ...files }
                                    newFiles[takerDocumentUpload.id] = f;
                                    setFiles(newFiles);
                                }}
                                open={!!openRow[takerDocumentUpload.id]}
                                setOpen={(o) => {
                                    setRowOpen({
                                        [takerDocumentUpload.id]: o
                                    })
                                }}
                                index={i}
                                takerDocumentUploads={takerDocumentUploads}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SimpleModalWrapper>
    );
}
