import React, { Suspense } from 'react';
import {
    $applyNodeReplacement,
    $isElementNode,
    DecoratorNode,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedElementNode,
    SerializedLexicalNode,
    Spread,
} from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ReportEdit } from '../../../../types/taker/reportstate.generated';
import EditorThemeClasses from "../../themes/theme";
import UpdateReportSectionPlugin from '../../plugins/UpdateReportSectionPlugin';
import FloatingReportSectionToolbarPlugin from '../../plugins/FloatingReportSectionToolbarPlugin';
import ForceUpdateEditorStatePlugin from '../../plugins/ForceUpdateEditorStatePlugin';
import SyncInnerReportSectionNodesPlugin from '../../plugins/SyncInnerReportSectionNodesPlugin';
import { InnerReportSectionNode } from '../InnerReportSectionNode';

export interface ReportSectionPayload {
    children: any[];
    sectionNamespace: string;
    sectionId: string;
    sectionHash: string;
    aiGenerated: boolean;
    ordinal: number;
}

export interface ReportSectionUpdatePayload {
    /* Whether the edit to this section is out of sync with the children. */
    outOfSync: boolean;

    /* Whether the content in this section was originally AI generated. */
    aiGenerated: boolean;

    /* Data copied from an existing edit, or initial child elements. */
    editIdentifier: string;
    editLexical: string;
    ogLexical: string;

    /* Hash from data that is being overridden. */
    sectionHash: string;
};

export type SerializedReportSectionNode = Spread<
    {
        children: any[];
        sectionNamespace: string;
        sectionId: string;
        sectionHash: string;
        aiGenerated: boolean;
        ordinal: number;
        type: "report-section",
        version: number
    },
    SerializedLexicalNode
>;

const buildRecursivelySerializedNode = (node: LexicalNode): SerializedLexicalNode | SerializedElementNode => {
    if ($isElementNode(node)) {
        return {
            ...node.exportJSON(),
            children: node.getChildren().map(buildRecursivelySerializedNode)
        };
    }
    return node.exportJSON();
}

export class ReportSectionNode extends DecoratorNode<JSX.Element> {
    __sectionNamespace: string;
    __sectionId: string;
    __sectionHash: string;
    __aiGenerated: boolean;
    __ordinal: number;

    __children: any[];

    /** @internal */
    __reportEdit?: ReportEdit;
    __metadata: Record<string, any>;

    static override getType(): string {
        return "report-section";
    }

    static override clone(node: ReportSectionNode): ReportSectionNode {
        return new ReportSectionNode(
            node.__children,
            node.__sectionNamespace,
            node.__sectionId,
            node.__sectionHash,
            node.__aiGenerated,
            node.__ordinal
        );
    }

    static override importJSON(serializedNode: SerializedReportSectionNode): ReportSectionNode {
        const node = $createReportSectionNode({
            children: serializedNode.children,
            sectionNamespace: serializedNode.sectionNamespace,
            sectionId: serializedNode.sectionId,
            sectionHash: serializedNode.sectionHash,
            aiGenerated: serializedNode.aiGenerated,
            ordinal: serializedNode.ordinal,
        });
        return node;
    }

    exportJSON(): SerializedReportSectionNode {
        return {
            children: this.__children,
            sectionNamespace: this.__sectionNamespace,
            sectionId: this.__sectionId,
            sectionHash: this.__sectionHash,
            aiGenerated: this.__aiGenerated,
            ordinal: this.__ordinal,
            type: "report-section",
            version: 1
        };
    }

    createDOM(config: EditorConfig): HTMLElement {
        const element = document.createElement('span');
        element.setAttribute("id", `${this.buildIdentifier()}`);
        return element;
    }

    updateDOM(
        prevNode: ReportSectionNode,
        element: HTMLElement,
        config: EditorConfig,
    ): boolean {
        return false;
    }

    constructor(
        children: any[],
        sectionNamespace: string,
        sectionId: string,
        sectionHash: string,
        aiGenerated: boolean,
        ordinal: number,
        key?: NodeKey
    ) {
        super(key);
        this.__children = children;
        this.__sectionNamespace = sectionNamespace;
        this.__sectionId = sectionId;
        this.__sectionHash = sectionHash;
        this.__aiGenerated = aiGenerated;
        this.__ordinal = ordinal;
        this.__metadata = {};
    }
    exportUpdatePayload(): ReportSectionUpdatePayload {
        const ogLexical = JSON.stringify(this.buildRootContainer());
        if (this.__reportEdit) {
            return {
                outOfSync: this.isOutOfSync(),
                aiGenerated: this.__aiGenerated,
                editIdentifier: this.__reportEdit.identifier,
                editLexical: this.__reportEdit.lexical,
                ogLexical: ogLexical,
                sectionHash: this.__sectionHash
            };
        }
        return {
            outOfSync: false,
            aiGenerated: this.__aiGenerated,
            editIdentifier: this.buildIdentifier(),
            editLexical: ogLexical,
            ogLexical: ogLexical,
            sectionHash: this.__sectionHash
        };
    }

    decorate(): JSX.Element {
        const identifier = this.buildIdentifier();
        const lexicalEditorState = this.__reportEdit?.lexical || JSON.stringify(this.buildRootContainer());
        const reportSectionNode = this.exportUpdatePayload();
        return (
            <Suspense fallback={null}>
                <LexicalComposer
                    initialConfig={{
                        editable: false,
                        namespace: identifier,
                        theme: EditorThemeClasses,
                        onError(error: any) {
                            console.error(error);
                        },
                        editorState: lexicalEditorState,
                        nodes: [
                            HeadingNode,
                            ListNode,
                            ListItemNode,
                            TableNode,
                            TableCellNode,
                            TableRowNode,
                            InnerReportSectionNode
                        ]
                    }}
                >
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable
                                style={{
                                    display: "inline"
                                }}
                            />
                        }
                        placeholder={<span></span>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ListPlugin />
                    <TablePlugin />
                    <UpdateReportSectionPlugin
                        reportSectionNode={reportSectionNode}
                    />
                    <FloatingReportSectionToolbarPlugin
                        reportSectionNode={reportSectionNode}
                    />
                    <ForceUpdateEditorStatePlugin newEditorState={lexicalEditorState}/>
                    <SyncInnerReportSectionNodesPlugin
                        outOfSync={this.isOutOfSync()}
                        overriden={!!this.__reportEdit}
                    />
                </LexicalComposer>
            </Suspense>
        );
    }

    buildIdentifier(): string {
        return `${this.__sectionNamespace}-${this.__sectionId}-${this.__ordinal}`;
    }

    isOutOfSync(): boolean {
        return !!this.__reportEdit && (this.__sectionHash !== this.__reportEdit.sectionHash)
    }

    buildRootContainer() {
        return {
            "root": {
                "direction": "ltr",
                "format": "",
                "indent": 0,
                "type": "root",
                "version": 1,
                "children": [
                    {
                        "children": this.__children,
                        "type": "inner-report-section",
                        "version": 1
                    }
                ]
            }
        };
    }
}

export function $createReportSectionNode({
    children,
    sectionNamespace,
    sectionId,
    sectionHash,
    aiGenerated,
    ordinal
}: ReportSectionPayload): ReportSectionNode {
    return $applyNodeReplacement(
        new ReportSectionNode(
            children,
            sectionNamespace,
            sectionId,
            sectionHash,
            aiGenerated,
            ordinal
        )
    );
}

export function $isReportSectionNode(node: LexicalNode | null | undefined,): node is ReportSectionNode {
    return node instanceof ReportSectionNode;
}
