import { useCallback, useEffect, useMemo, useState } from "react";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { Button, CircularProgress } from "@mui/material/";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { KeyTermSummaryUpdate, useKeyTermGroupState } from "../../../containers/TakerDocumentState/KeyTermGroupState";
import SummaryRow, { RowData, createData } from "./SummaryRow";
import GeneratedSummary from "./GeneratedSummary";
import { useKeyTermsWrapper } from "../../../containers/WidgetWrapper/wrapper";

type DisplayState = undefined | "results-table" | "result";

interface GeneratedSummariesProps {
    readOnly: boolean;
}

const GeneratedSummaries = ({
    readOnly,
}: GeneratedSummariesProps) => {
    const { documentKeyTermsService, takerDocumentUpload } = useKeyTermGroupState();
    const { latestExecSummaryAnalyses } = useTakerState();
    const { state, mutateState } = useKeyTermsWrapper();

    const [displayState, setDisplayState] = useState<DisplayState>();
    const [selectedId, setSelectedId] = useState<string | undefined>();
    const [selectedKts, setSelectedKts] = useState<KeyTermSummaryUpdate[]>([]);
    const { showAiSummaries } = state;

    const setOpen = (o: boolean) => {
        mutateState({ showAiSummaries: o })
    };

    useEffect(() => {
        setDisplayState("results-table");
    }, [showAiSummaries]);

    const rows: RowData[] = useMemo(() => {
        const rows: any[] = [];
        if (latestExecSummaryAnalyses) {
            const sortedByCreated = latestExecSummaryAnalyses.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
            for (let esa of sortedByCreated) {
                rows.push(
                    createData(
                        esa.id,
                        (esa.state === "PENDING_GENERATION"),
                        esa.createdAt,
                    )
                );
            }
        }
        return rows;
    }, [latestExecSummaryAnalyses]);

    const selectedRow = useMemo(() => {
        for (const row of rows) {
            if (selectedId === row.id) {
                return row;
            }
        }
        return null;
    }, [selectedId, rows]);

    const addAllSelected = useCallback(() => {
        setDisplayState(undefined);
        setSelectedId(undefined);
        documentKeyTermsService.updateKeyTermSummaries(selectedKts);
        setSelectedKts([]);
        setOpen(false);
    }, [selectedKts, documentKeyTermsService]);

    const buttonElements = [];
    buttonElements.push(
        <Button
            variant="outlined"
            onClick={() => setOpen(false)}
        >
            Cancel
        </Button>
    );

    if ((displayState === "result") && selectedRow) {
        buttonElements.push(
            <Button
                sx={{
                    marginLeft: "5px"
                }}
                variant="outlined"
                onClick={() => {
                    setDisplayState("results-table");
                    setSelectedId(undefined);
                }}
            >
                Back
            </Button>
        );
        if (!readOnly) {
            buttonElements.push(
                <Button
                    sx={{
                        float: "right"
                    }}
                    variant="contained"
                    onClick={addAllSelected}
                >
                    Add
                </Button>
            );
        }
    }

    return (
        <SimpleModalWrapper
            headerText={`Generated Summaries - ${takerDocumentUpload?.name}`}
            open={showAiSummaries}
            handleClose={() => setOpen(false)}
            maxWidth='lg'
            buttonElements={buttonElements}
        >
            <>
                {!displayState && (
                    <CircularProgress />
                )}
                {(displayState === "results-table") && (
                    <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>
                                        Created
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, i) => (
                                    <SummaryRow
                                        key={i}
                                        row={row}
                                        onClick={() => {
                                            setSelectedId(row.id);
                                            setDisplayState("result");
                                        }}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {(displayState === "result") && selectedRow && (
                    <GeneratedSummary
                        readOnly={readOnly}
                        selectedRow={selectedRow}
                        setSelectedKts={setSelectedKts}
                    />
                )}
            </>
        </SimpleModalWrapper>
    );
}

export default GeneratedSummaries;
