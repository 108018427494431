import React, { useEffect, useState } from "react";
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

export interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
}

interface EnhancedTableProps {
    disableSelection: boolean;
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
    headers: readonly HeadCell[];
}

function EnhancedTableHead({
    disableSelection,
    onSelectAllClick, 
    numSelected, 
    rowCount, 
    headers
}: EnhancedTableProps) {
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={disableSelection}
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headers.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="right"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    status: string;
    numSelected: number;
}

function EnhancedTableToolbar({
    status,
    numSelected
}: EnhancedTableToolbarProps) {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="subtitle1"
                    id="tableTitle"
                    component="div"
                >
                    {status}
                </Typography>
            )}
        </Toolbar>
    );
}

interface RowSelectableTableProps {
    disableSelection: boolean;
    headers: readonly HeadCell[];
    rows: any[];
    status: string;
    mapFirstCol: (d: any) => any;
    onUpdateSelectedRows: (is: number[]) => void;
    initiallySelected?: number[]
}

const RowSelectableTable = ({
    disableSelection,
    headers,
    rows,
    status,
    mapFirstCol,
    onUpdateSelectedRows,
    initiallySelected
}: RowSelectableTableProps) => {
    const [selected, setSelected] = useState<number[]>(initiallySelected || []);

    useEffect(() => {
        onUpdateSelectedRows(selected);
    }, [selected]);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected(rows.map((r, i) => i));
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, i: number) => {
        const selectedIndex = selected.indexOf(i);
        let newSelected: number[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, i);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (i: number) => {
        return selected.indexOf(i) !== -1;
    };

    return (
        <>
            <EnhancedTableToolbar
                status={status}
                numSelected={selected.length} />
            <TableContainer>
                <Table>
                    <EnhancedTableHead
                        disableSelection={disableSelection}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={rows.length}
                        headers={headers}
                    />
                    <TableBody>
                        {rows.map((row, index) => {
                            const isItemSelected = isSelected(index);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const firstCol = mapFirstCol(row);
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => {
                                        if (!disableSelection) {
                                            handleClick(event, index);
                                        }
                                    }}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={index}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        {firstCol ? (
                                            firstCol
                                        ) : (
                                            <Checkbox
                                                disabled={disableSelection}
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    {headers.map((header) => (
                                        <TableCell align="right">
                                            {row[header.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                        {(rows.length === 0) && (
                            <TableRow
                                style={{
                                    height: 53,
                                }}
                            >
                                <TableCell colSpan={headers.length + 1} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default RowSelectableTable;