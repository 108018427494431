import { createApi } from '@reduxjs/toolkit/query/react';
import { IndexedGuidance, IndexedGuidanceContent } from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import { PaginatedResponse } from '../models/commonTypes';

export const indexedGuidanceApi = createApi({
    reducerPath: 'indexedGuidanceApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['IndexedGuidances', 'IndexedGuidanceContents', 'IndexedGuidancePackages'],
    endpoints: (build) => ({
        listIndexedGuidances: build.query<PaginatedResponse<IndexedGuidance>, { page: number | void, limit: number | void }>({
            query: ({page = 1, limit = 10}) => ({
                url: `indexed_guidance?page=${page}&limit=${limit}`,
                method: "GET"
            }),            
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'IndexedGuidances', id } as const)),
                        { type: 'IndexedGuidances', id: 'LIST' },
                    ]
                :
                    [{ type: 'IndexedGuidances', id: 'LIST' }],
        }),
        addIndexedGuidance: build.mutation<IndexedGuidance, (Partial<IndexedGuidance> & {fileLabel?: string; fileType?: string; packageFileLabel?: string; packageFileType?: string;})>({
            query(data) {
                const { 
                    guidanceId,
                    name, 
                    description, 
                    canonicalLabel,
                    fileLabel, 
                    fileType,
                    packageFileLabel,
                    packageFileType
                } = data;
                return {
                    url: `indexed_guidance`,
                    method: 'POST',
                    data: {
                        guidance_id: guidanceId,
                        name,
                        description,
                        canonical_label: canonicalLabel,
                        file_label: fileLabel,
                        file_type: fileType,
                        package_file_label: packageFileLabel,
                        package_file_type: packageFileType
                    }
                }
            },
            invalidatesTags: [{ type: 'IndexedGuidances', id: 'LIST' }],
        }),
        initializeIndexedGuidanceFromPackage: build.mutation<IndexedGuidance, string>({
            query(id) {
                return {
                    url: `indexed_guidance/${id}/initialize_from_package`,
                    method: 'POST'
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'IndexedGuidances', id }],
        }),
        getIndexedGuidance: build.query<IndexedGuidance, string>({
            query: (id) => ({
                url: `indexed_guidance/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [{ type: 'IndexedGuidances', id }],
        }),
        updateIndexedGuidance: build.mutation<IndexedGuidance, Partial<IndexedGuidance>>({
            query(data) {
                const { id, guidanceId, name, description, canonicalLabel, referenceMap } = data;
                return {
                    url: `indexed_guidance/${id}`,
                    method: 'PUT',
                    data: {
                        guidance_id: guidanceId,
                        name, 
                        description,
                        reference_map: referenceMap,
                        canonical_label: canonicalLabel
                    }                    
                }
            },
        }),
        deleteIndexedGuidance: build.mutation<boolean, string>({
            query(id) {
                return {
                    url: `indexed_guidance/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'IndexedGuidances', id }],
        }),
        getIndexedGuidanceContent: build.query<IndexedGuidanceContent, {id: string; indexedGuidanceId: string;}>({
            query: ({ id, indexedGuidanceId}) => ({
                url: `/indexed_guidance/${indexedGuidanceId}/content/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, { id, indexedGuidanceId }) => [{ type: 'IndexedGuidanceContents', id: `${id}-${indexedGuidanceId}` }],
        }),
        upsertIndexedGuidanceContent: build.mutation<IndexedGuidance, Partial<IndexedGuidanceContent>>({
            query(data) {
                const { id, indexedGuidanceId, content, htmlContent, lexicalContent } = data;
                return {
                    url: `/indexed_guidance/${indexedGuidanceId}/content/${id}`,
                    method: 'POST',
                    data: {
                        content: content,
                        html_content: htmlContent,
                        lexical_content: lexicalContent
                    }                    
                }
            },
            invalidatesTags: (result, error, { id, indexedGuidanceId }) => [{ type: 'IndexedGuidanceContents', id: `${id}-${indexedGuidanceId}` }],
        }),
        searchIndexedGuidanceByName: build.query<PaginatedResponse<IndexedGuidance>, { page: number | void, limit: number | void, name: string | void }>({
            query: ({page = 1, limit = 10, name = ''}) => ({
                url: `indexed_guidance/name_search?page=${page}&limit=${limit}&name=${name}`,
                method: "GET"
            }),
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'IndexedGuidances', id } as const)),
                        { type: 'IndexedGuidances', id: 'NAME_SEARCH' },
                    ]
                :
                    [{ type: 'IndexedGuidances', id: 'NAME_SEARCH' }],
        }),
        getIndexedGuidancePackageVersions: build.query<string[], string>({
            query: (id) => ({
                url: `indexed_guidance/${id}/versions`,
                method: "GET"
            })
        }),
        createIndexedGuidancePackage: build.mutation<void, string>({
            query(id) {
                return {
                    url: `/indexed_guidance/${id}/create_package`,
                    method: 'POST'                   
                }
            }
        })
    }),
});

export const {
    useListIndexedGuidancesQuery,
    useAddIndexedGuidanceMutation,
    useInitializeIndexedGuidanceFromPackageMutation,
    useGetIndexedGuidanceQuery,
    useUpdateIndexedGuidanceMutation,
    useDeleteIndexedGuidanceMutation,
    useLazyGetIndexedGuidanceContentQuery,
    useUpsertIndexedGuidanceContentMutation,
    useSearchIndexedGuidanceByNameQuery,
    useGetIndexedGuidancePackageVersionsQuery,
    useCreateIndexedGuidancePackageMutation
} = indexedGuidanceApi;
