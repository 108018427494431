import React from "react";
import { CircularProgress } from "@mui/material/";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ArrowForward, Check } from "@mui/icons-material";
import { CrudButtonGroup } from "../../../components/buttons/crudButtonGroup";

export interface RowData {
    id: string;
    pendingGeneration: boolean;
    createdAt: number;
}

export function createData(
    id: string,
    pendingGeneration: boolean,
    createdAt: number,
): RowData {
    return {
        id,
        pendingGeneration,
        createdAt,
    };
}

interface SummaryRowProps {
    row: RowData;
    onClick: () => void;
}

const SummaryRow = ({
    row,
    onClick
}: SummaryRowProps) => {
    return (
        <TableRow
            sx={{
                '& > *': { borderBottom: 'unset' }
            }}
        >
            <TableCell>
                {row.pendingGeneration ? (
                    <CircularProgress size={20} />
                ) : (
                    <Check />
                )}
            </TableCell>
            <TableCell component="th" scope="row">
                {new Date(row.createdAt).toLocaleString()}
            </TableCell>
            <TableCell scope="row">
                <CrudButtonGroup
                    buttons={[
                        {
                            disabled: row.pendingGeneration,
                            icon: (<ArrowForward />),
                            handleClick: () => onClick()
                        }
                    ]}
                />
            </TableCell>
        </TableRow>
    );
};

export default SummaryRow;