import React, { useState, useContext, useEffect, PropsWithChildren, useRef, useMemo, useCallback, memo, Suspense } from 'react';
import {
  Taker,
  TakerDocument,
  TakerDocumentUpload,
  TakerDocumentUploadAnalysis,
  TakerDocumentAnalysis,
  TakerAccessType,
  TakerDocumentSettingsPropertyType,
  TakerDocumentData,
  TakerDocumentReviewComment,
  CommentType
} from "../../redux/models/dataModelTypes";
import { FulfillmentStateHolder, TakerStateHolder } from "../../types/taker";
import { Button, CircularProgress, IconButton } from '@mui/material';
import { CloseOutlined, Refresh } from '@mui/icons-material';
import { OptionsWithExtraProps, useSnackbar } from 'notistack';
import socket from '../../utils/socket';
import { dummyTakerStateData } from './dummyTakerStateData';
import {
  useUpdateTakerMutation,
  useAddContentFilteringAnalysisMutation,
  useAddExecSummaryAnalysisMutation,
  useAddSingleSummaryAnalysisMutation,
  useGetTakerDocumentQuery,
  useGetTakerDocumentStateV2Query,
  useAddTakerDocumentReviewCommentMutation,
  useUpdateTakerDocumentReviewCommentMutation,
} from '../../redux/services/taker';
import {
  useGetLatestTakerDocumentDataQuery
} from '../../redux/services/takerData';
import { VariableReference } from '../../types/builderv2.generated';
import { useSelector } from '../../redux/reduxUtils/functions';
import { RootReducerType } from '../../redux/models/reduxTypes';
import { ApiError } from '../../redux/reduxUtils/baseQuery';
import { useReadOnlyBuilderData } from '../ReadOnlyBuilderData/ReadOnlyBuilderData';
import { CommentaryOrGuidanceReference, KeyTermSource } from '../../types/taker/uidatastate.generated';
import { useUserScopedAppData } from '../UserScopedAppData/UserScopedAppData';
import { useLocalStorage } from '@uidotdev/usehooks';

const SAVE_DELAY = 200;
const METADATA_SAVE_DELAY = 1000;
const ANALYSIS_ID_ARRAY = "analysis_ids";

const TOP_CENTER_ERROR_OPTION = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
} as OptionsWithExtraProps<'error'>;

const dummyTakerState = new TakerStateHolder(dummyTakerStateData);

const _getJsonWithDefault = (key: string, defaultReturnVal: Object | Array<any>) => {
  let strVal = localStorage.getItem(key)
  if (strVal) {
    return JSON.parse(strVal);
  }
  return defaultReturnVal;
};

const _updateJson = (key: string, val: Object | Array<any>) => {
  localStorage.setItem(key, JSON.stringify(val));
};

const getAnalysisIds = (): Array<string> => _getJsonWithDefault(ANALYSIS_ID_ARRAY, []);
const updateAnalysisIds = (anaysisIds: Array<any>) =>
  _updateJson(ANALYSIS_ID_ARRAY, anaysisIds);

type TakerNameGetter = () => string | null;

interface TakerPermissionState {
  allGrants: TakerAccessType[];
  isUnknown: boolean;
  isForbidden: boolean;
  isRead: boolean;
  isReadWrite: boolean;
  isAdmin: boolean;
};

interface QuestionnaireDataService {
  updateAnswer: (moduleIds: string[], q: string, value: any, iteration: number | null) => void;
  updateAnswers: (moduleIds: string[], nameValuePairs: { name: string; value: any; }[], iteration: number | null) => void;
  updateAnalysis: (moduleIds: string[], q: string, value: string, iteration: number | null) => void;
  updateReference: (moduleIds: string[], q: string, value: CommentaryOrGuidanceReference[], iteration: number | null) => void;
  updateSource: (moduleIds: string[], q: string, value: KeyTermSource[], iteration: number | null) => void;
  getAnswer: (moduleIds: string[], q: string, iteration: number | null) => any;
  getAnalysis: (moduleIds: string[], q: string, iteration: number | null) => string | undefined;
  getReference: (moduleIds: string[], q: string, iteration: number | null) => CommentaryOrGuidanceReference[];
  getSource: (moduleIds: string[], q: string, iteration: number | null) => KeyTermSource[];
  getMockAnswer: (moduleIds: string[], q: string, iteration: number | null) => any;
  getMockAnalysis: (moduleIds: string[], q: string, iteration: number | null) => string | undefined;
  getMockReference: (moduleIds: string[], q: string, iteration: number | null) => CommentaryOrGuidanceReference[];
  getMockSource: (moduleIds: string[], q: string, iteration: number | null) => KeyTermSource[];
  addIterations: (moduleIds: string[], n: number) => void;
  removeIteration: (moduleIds: string[], iteration: number) => void;
  count: (moduleIds: string[]) => number;
};

interface TakerStateHookData {
  isSaving: boolean;
  initTakerStateCount: number;
  isTakerDocumentUninitialized: boolean;
  isQuestionnaireUninitialized: boolean;
  isTakerDocumentLoading: boolean;

  taker: Taker | undefined;
  lastSavedTimestamp: number | undefined;
  takerDocumentId: string;
  latestQuestionnaireDataId?: string;
  latestQuestionnaireData?: TakerDocumentData | undefined;
  takerDocumentUploads: Array<TakerDocumentUpload> | undefined;
  activeTakerDocument: TakerDocument | null;
  refetchTakerDocument: () => void;

  takerPermissionState: TakerPermissionState;



  latestContentFilterAnalyses: Record<string, TakerDocumentUploadAnalysis>;
  latestExecSummaryAnalyses: Array<TakerDocumentAnalysis>;
  latestDocQAAnalyses: Array<TakerDocumentAnalysis>;

  createSummaryAnalyses: () => void;
  createSingleSummary: (keyTermId: string, takerDocumentUploadId: string, jobType: string, existingSummary: string) => void;
  createContentFilteringAnalyses: (tdu: TakerDocumentUpload, fiid: string) => void;

  takerName: string;
  updateTakerName: (n: string) => void;
  activeTargetTakerState: TakerStateHolder;
  isTakerStateDirty: boolean;

  questionnareDataService: QuestionnaireDataService;

  takerOutput: any;
  fulfillmentStateHolder: FulfillmentStateHolder | undefined,
  loadingTakerOutput: boolean;
  getVariableValue: (mids: string[], varRef: VariableReference) => any;
  getActiveSetting: (type: TakerDocumentSettingsPropertyType) => any;

  // comments
  commentsOpen: boolean;
  setCommentsOpen: (o: boolean) => void;
  reviewComments: Array<TakerDocumentReviewComment>;
  addReviewComment: (
    lexical: any,
    text: string,
    commentType: CommentType,
    commentMetadata: Record<string, any>
  ) => void;
  updateReviewComment: (
    commentId: string,
    lexical: any,
    text: string
  ) => void;
}

type TakerStateHook = () => TakerStateHookData;

const Context = React.createContext({});

export const useTakerState: TakerStateHook = () => {
  return useContext(Context) as TakerStateHookData;
}


interface TakerDocumentContainerProps {
  originalTaker: Taker;
  takerDocumentId: string;
  userId: string;
}

const TakerDocumentContainer: React.FC<PropsWithChildren<TakerDocumentContainerProps>> = ({
  originalTaker,
  takerDocumentId,
  userId,
  children
}) => {
  const [updatingJob, setUpdatingJob] = useState<any>(null);
  const [updatingMetadataJob, setUpdatingMetadataJob] = useState<any>(null);
  const [initTakerStateCount, setInitTakerStateCount] = useState<number>(0);
  const [takerName, setTakerName] = useState<string | undefined>(undefined);
  const [isStateDirty, setIsStateDirty] = useState<boolean>(false);
  const [lastMarkedClean, setLastMarkedClean] = useState<number>();
  const [analysisIds, setAnalysisIds] = useState<Array<string>>([]);
  const [commentsOpen, setCommentsOpen] = useLocalStorage<boolean>(`${takerDocumentId}-commentsOpen`, false);

  const preventUpdateOutput = useRef(false);

  const {
    enqueueSnackbar,
    closeSnackbar
  } = useSnackbar();

  const [updateTaker, updateTakerResult] = useUpdateTakerMutation();
  const [addContentFilteringAnalysis, addContentFilteringAnalysisResult] = useAddContentFilteringAnalysisMutation();
  const [addExecSummaryAnalysis, addExecSummaryAnalysisResult] = useAddExecSummaryAnalysisMutation();
  const [addSingleSummaryAnalysis, addSingleSummaryAnalysisResult] = useAddSingleSummaryAnalysisMutation();
  const { defaultTakerDocumentSettings } = useReadOnlyBuilderData();
  const [
    addTakerDocumentReviewComment,
    addTakerDocumentReviewCommentRes
  ] = useAddTakerDocumentReviewCommentMutation();
  const [
    updateTakerDocumentReviewComment,
    updateTakerDocumentReviewCommentRes
  ] = useUpdateTakerDocumentReviewCommentMutation();

  function isTakerDocumentAnalysis(obj: any): obj is TakerDocumentAnalysis {
    return obj && typeof obj === 'object' && 'someUniqueProperty' in obj;
  }

  const {
    data: latestQuestionnaireData,
    isUninitialized: isQuestionnaireDataUninitialized,
    isError: isQuestionnaireDataError
  } = useGetLatestTakerDocumentDataQuery({ takerDocumentId, contentType: "QUESIONNAIRE" });

  const { user } = useSelector((state: RootReducerType) => state.auth);
  const isDebugger = useMemo(() => !!user?.roles.includes('DEBUGGER'), [user]);

  const { flagProvider } = useUserScopedAppData();

  const enableContentFilteringV2 = useMemo(() => {
    let flags = flagProvider.populateFlagValues([
      "FEATURE_DOCUMENT_TAGGING__use_v2_tagger"
    ])
    if (flags.FEATURE_DOCUMENT_TAGGING__use_v2_tagger === "TRUE") {
      return true;
    } else {
      return false
    }
  }, [flagProvider])

  const {
    data: activeTakerDocument,
    isFetching: isTakerDocumentLoading,
    isUninitialized: isTakerDocumentUninitialized,
    refetch: refetchTakerDocument
  } = useGetTakerDocumentQuery(takerDocumentId);
  const {
    data: takerDocumentStateV2,
    isSuccess: isTakerDocumentStateV2Success,
    isFetching: isTakerDocumentStateV2IsFetching
  } = useGetTakerDocumentStateV2Query(takerDocumentId)

  useEffect(() => {
    if (isStateDirty && takerDocumentStateV2) {
      setIsStateDirty(false);
      setLastMarkedClean(new Date().getTime());
    }
  }, [takerDocumentStateV2]);

  const [takerDocumentUploadsState, setTakerDocumentUploads] = useState<Array<TakerDocumentUpload> | undefined>(activeTakerDocument?.takerDocumentUploads);

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    function onConnect() {
      console.log('connect');
      socket.emit("join", `taker_document:${takerDocumentId}`);
    }

    function onDisconnect() {
      console.log('disconnect');
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.emit("leave", `taker_document:${takerDocumentId}`);
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [takerDocumentId]);

  const checkPendingReview = (
    tda: TakerDocumentAnalysis | TakerDocumentUploadAnalysis
  ) => {
    return (
      tda.state === "PENDING_GENERATION" &&
      (new Date().getTime() - tda.updatedAt) < (10000 * 600)
    );
  };

  // Check document for active analyses, and display these as notifications.
  // Also schedule a refresh for a later time.
  useEffect(() => {
    const analyses: Array<{ message: string, tda?: TakerDocumentAnalysis, tdua?: TakerDocumentUploadAnalysis }> = [];
    if (activeTakerDocument?.executiveSummaryTakerDocumentAnalyses) {
      for (let execSummaryAnalysis of activeTakerDocument?.executiveSummaryTakerDocumentAnalyses) {
        if (checkPendingReview(execSummaryAnalysis)) {
          analyses.push({
            message: `Summmarizing`,
            tda: execSummaryAnalysis,
          });
        }
      }
    }

    if (activeTakerDocument?.takerDocumentUploads) {
      for (let tdu of activeTakerDocument?.takerDocumentUploads) {
        for (let contentFilterAnalysis of tdu.contentFilteringTakerDocumentUploadAnalyses) {
          if (checkPendingReview(contentFilterAnalysis)) {
            let fileItemId = contentFilterAnalysis.data['file_item_id'];
            let targetFileItem = tdu.fileUpload.fileUploadItems.find(fui => fui.id === fileItemId);
            analyses.push({
              message: `Processing Document - "${targetFileItem?.label}"`,
              tdua: contentFilterAnalysis,
            });
          }
        }
      }
    }

    if (activeTakerDocument?.documentQATakerDocumentAnalyses) {
      for (let docQAAnalysis of activeTakerDocument?.documentQATakerDocumentAnalyses) {
        if (checkPendingReview(docQAAnalysis)) {
          analyses.push({
            message: `Answering Questions`,
            tda: docQAAnalysis,
          });
        }
      }
    }

    let removedAnalysisIds = getAnalysisIds();

    for (const { tda, tdua, message } of analyses) {
      let analysis = !!tda ? tda : tdua;
      if (analysis && !analysisIds.includes(analysis.id) && !removedAnalysisIds.includes(analysis.id)) {
        const snackbarData = {
          key: analysis.id,
          preventDuplicate: true,
          variant: 'pendingJob',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          persist: true,
          action: (
            <>
              <CircularProgress size={25} color='inherit' />
              <IconButton
                onClick={() => {
                  if (analysis) {
                    closeSnackbar(analysis.id);
                    removedAnalysisIds.push(analysis.id);
                    updateAnalysisIds(removedAnalysisIds);
                  }
                }}
                color='inherit'
                size='small'
              >
                <CloseOutlined />
              </IconButton>
            </>
          )
        } as any;

        if (tda) {
          snackbarData['takerDocumentAnalysisId'] = tda.id;
        }
        if (tdua) {
          snackbarData['takerDocumentUploadAnalysisId'] = tdua.id;
        }

        enqueueSnackbar(message, snackbarData);
      }
    }

    const currentAnalysisIds = analyses
      .map(a => !!a.tda ? a.tda.id : (a.tdua && a.tdua.id))
      .filter(Boolean) as string[];
    for (const existing of analysisIds) {
      if (!currentAnalysisIds.includes(existing)) {
        closeSnackbar(existing);
      }
    }

    // update analysis ids
    setAnalysisIds(currentAnalysisIds);
    setTakerDocumentUploads(activeTakerDocument?.takerDocumentUploads);
  }, [activeTakerDocument]);

  useEffect(() => {
    if (addContentFilteringAnalysisResult.isError && addContentFilteringAnalysisResult.error) {
      enqueueSnackbar(
        (addContentFilteringAnalysisResult.error as ApiError).data,
        TOP_CENTER_ERROR_OPTION
      );
    }
  }, [addContentFilteringAnalysisResult]);

  useEffect(() => {
    if (addExecSummaryAnalysisResult.isError && addExecSummaryAnalysisResult.error) {
      enqueueSnackbar(
        (addExecSummaryAnalysisResult.error as ApiError).data,
        TOP_CENTER_ERROR_OPTION
      );
    }
  }, [addExecSummaryAnalysisResult]);

  const takerPermissionState: TakerPermissionState = useMemo(() => {
    if (!activeTakerDocument) {
      return {
        allGrants: [],
        isUnknown: true,
        isForbidden: false,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    if (!activeTakerDocument.state) {
      return {
        allGrants: [],
        isUnknown: true,
        isForbidden: false,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    const allGrants: TakerAccessType[] = [];
    if (originalTaker.ownerUserId === userId) {
      allGrants.push("OWNER");
    }

    for (const takerDocumentAccessGrant of activeTakerDocument.takerDocumentAccessGrants) {
      if (takerDocumentAccessGrant.userId === userId) {
        allGrants.push(takerDocumentAccessGrant.type);
      }
    }

    if (allGrants.length === 0) {
      return {
        allGrants,
        isUnknown: false,
        isForbidden: true,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    let hasRead = allGrants.includes("READ");
    let hasOwner = allGrants.includes("OWNER");
    let hasReview = allGrants.includes("REVIEW");

    let inCompleted = activeTakerDocument.state.reviewState === "COMPLETED";
    if (inCompleted && (allGrants.length > 0)) {
      return {
        allGrants,
        isUnknown: false,
        isForbidden: false,
        isRead: true,
        isReadWrite: false,
        isAdmin: hasOwner
      };
    }

    let inReview = activeTakerDocument.state.reviewState === "PENDING";
    return {
      allGrants,
      isUnknown: false,
      isForbidden: false,
      isRead: (hasRead || hasOwner || hasReview),
      isReadWrite: (!inReview && hasOwner) || (inReview && hasReview),
      isAdmin: hasOwner
    };
  }, [activeTakerDocument, originalTaker, userId]);

  // Decides between the mutated state and the original state.
  const activeTargetTakerState = useRef<TakerStateHolder | undefined>(
    latestQuestionnaireData && new TakerStateHolder(latestQuestionnaireData.content)
  );

  useEffect(() => {
    if (!latestQuestionnaireData) {
      return;
    }

    activeTargetTakerState.current = new TakerStateHolder(latestQuestionnaireData.content);
    setInitTakerStateCount(initTakerStateCount + 1);
  }, [latestQuestionnaireData?.id]);

  const reviewComments = useMemo(() => {
    if (!activeTakerDocument || !activeTakerDocument.state) {
      return [];
    }

    let sortedReviewComments = [...activeTakerDocument.state.reviewComments];
    sortedReviewComments.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1);
    return sortedReviewComments;
  }, [
    activeTakerDocument,
    addTakerDocumentReviewCommentRes,
    updateTakerDocumentReviewCommentRes
  ]);

  const addReviewComment = useCallback(
    (
      lexical: any,
      text: string,
      commentType: "TOP_LEVEL" | "DOCUMENT_HIGHLIGHT",
      commentMetadata: Record<string, any>
    ) => {
      addTakerDocumentReviewComment({
        takerDocumentId,
        commentType,
        commentLexical: lexical,
        commentText: text,
        commentMetadata
      });
    },
    [
      takerDocumentId
    ]
  );

  const updateReviewComment = useCallback(
    (
      commentId: string,
      lexical: any,
      text: string
    ) => {
      updateTakerDocumentReviewComment({
        id: commentId,
        takerDocumentId,
        commentLexical: lexical,
        commentText: text,
      });
    },
    [
      takerDocumentId
    ]
  );

  // Decides between the mutated state and the original state.
  const getTakerName: TakerNameGetter = () => {
    if (!originalTaker) {
      return null;
    }
    return takerName ? takerName : originalTaker.name;
  };

  let latestContentFilterAnalyses: Record<string, TakerDocumentUploadAnalysis> = {};
  let latestDocQAAnalyses: Array<TakerDocumentAnalysis> = [];
  let latestExecSummaryAnalyses: Array<TakerDocumentAnalysis> = [];

  if (activeTakerDocument) {
    for (const tdu of activeTakerDocument.takerDocumentUploads) {
      const sortedContentFilterAnalysis = tdu.contentFilteringTakerDocumentUploadAnalyses.slice().sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
      if (sortedContentFilterAnalysis.length > 0) {
        latestContentFilterAnalyses[tdu.id] = sortedContentFilterAnalysis[0];
      }
    }

    const sortedDocQAAnalysis = activeTakerDocument.documentQATakerDocumentAnalyses.slice().sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
    latestDocQAAnalyses = sortedDocQAAnalysis;

    const sortedExecSummaryAnalyses = activeTakerDocument.executiveSummaryTakerDocumentAnalyses.slice().sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
    latestExecSummaryAnalyses = sortedExecSummaryAnalyses;
  }

  const getActiveSetting = (type: TakerDocumentSettingsPropertyType) => {
    if (activeTakerDocument?.settings) {
      for (const property of activeTakerDocument?.settings.properties) {
        if (property.type === type) {
          return property.value;
        }
      }
    }

    if (defaultTakerDocumentSettings) {
      for (const property of defaultTakerDocumentSettings.properties) {
        if (property.type === type) {
          return property.value;
        }
      }
    }
  }

  const createSummaryAnalyses = () => {
    if (activeTakerDocument?.takerDocumentUploads) {
      const disableLlm = getActiveSetting("KEY_TERM_SUMMARY_DISABLE_AI_GENERATED_CONTENT") === "true";
      addExecSummaryAnalysis({
        takerId: originalTaker.id,
        takerDocumentId: activeTakerDocument?.id,
        takerDocumentUploads: activeTakerDocument.takerDocumentUploads,
        disableLlm
      });
    } else {
      enqueueSnackbar("Cannot summarize without key term groups", {
        key: 'summary-error-no-key-term-groups',
        preventDuplicate: true,
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      });
    }
  };

  const createSingleSummary = async (keyTermId: string, takerDocumentUploadId: string, summaryType: string, existingSummary: string) => {
    if (activeTakerDocument?.takerDocumentUploads) {
      const disableLlm = getActiveSetting("KEY_TERM_SUMMARY_DISABLE_AI_GENERATED_CONTENT") === "true";
      addSingleSummaryAnalysis({
        takerId: originalTaker.id,
        takerDocumentId: activeTakerDocument?.id,
        takerDocumentUploadId: takerDocumentUploadId,
        summaryType: summaryType,
        existingSummary: existingSummary,
        keyTermId,
        disableLlm
      });
    }
  };

  const createContentFilteringAnalyses = (takerDocumentUpload: TakerDocumentUpload, fileItemId: string) => {
    const enableKeyTermTagging = getActiveSetting("KEY_TERM_TAGGING_ENABLED") === "true";
    addContentFilteringAnalysis({
      takerId: originalTaker.id,
      takerDocumentId,
      takerDocumentUpload: takerDocumentUpload,
      enableKeyTermTagging,
      fileItemId,
      enableContentFilteringV2
    });
  };

  // Do the taker output computation when the state changes. Save with a delay as to not overwhelm the server.
  const doTakerStateUpdateFunc = (newTakerState: TakerStateHolder, doImmediateUpdate: boolean) => {
    // no updates for non write roles
    if (!takerPermissionState.isReadWrite) {
      return
    }

    activeTargetTakerState.current = newTakerState;

    if (updatingJob) {
      clearTimeout(updatingJob)
    }

    function doUpdate() {
      if (latestQuestionnaireData && activeTargetTakerState.current) {
        setIsStateDirty(true);

        if (socket.connected) {
          socket.emit("update-taker-document-data-v2", {
            taker_document_data_id: latestQuestionnaireData.id,
            content: activeTargetTakerState.current.uiDataSchema,
            metadata: {
              prevent_update_taker_state: preventUpdateOutput.current,
              update_report: isDebugger
            }
          }, (v: any) => { });
        } else {
          enqueueSnackbar("There was a problem updating the automation.", {
            key: 'disconnected',
            preventDuplicate: true,
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            persist: true,
            action: (
              <Button
                startIcon={<Refresh />}
                onClick={() => window.location.reload()}
                color='inherit'
                size='small'
              >
                Refresh
              </Button>
            )
          });
        }

        preventUpdateOutput.current = false;
      }
    }

    if (doImmediateUpdate) {
      doUpdate();
    } else {
      let newUpdatingJob = setTimeout(doUpdate, SAVE_DELAY);
      setUpdatingJob(newUpdatingJob);
    }
  };

  const doTakerStateUpdate = useRef(doTakerStateUpdateFunc);
  useEffect(() => {
    doTakerStateUpdate.current = doTakerStateUpdateFunc;
  });

  const getVariableValue = (
    currentModuleIds: string[],
    varRef: VariableReference,
    currentTakerDocumentStateV2: any
  ) => {
    if (!currentTakerDocumentStateV2) {
      return;
    }

    let scopedVarsPtr = currentTakerDocumentStateV2["scoped_variables"];

    // This refers to a local variable, return from currentModuleIds scope.
    if (!varRef.modulePath) {
      const midsCopy = [...currentModuleIds];
      let firstMid = midsCopy.splice(0, 1)[0];
      if (firstMid !== scopedVarsPtr["module_id"]) {
        throw Error(`${firstMid} was not the first module ID in the path`)
      }

      while (midsCopy.length > 0) {
        let firstMid = midsCopy.splice(0, 1)[0];
        let found = false;
        for (const sv of scopedVarsPtr["nested_scoped_vars"]) {
          if (sv["module_id"] === firstMid) {
            scopedVarsPtr = sv;
            found = true;
            break;
          }
        }
        if (!found) {
          throw Error(`${firstMid} was not found in the available path`);
        }
      }
      return scopedVarsPtr["internal_variables"][varRef.variableName];
    }

    const midsCopy = [...varRef.modulePath];
    let firstMid = midsCopy.splice(0, 1)[0];
    if (firstMid !== scopedVarsPtr["module_id"]) {
      throw Error(`${firstMid} was not the first module ID in the path`)
    }

    while (midsCopy.length > 0) {
      let firstMid = midsCopy.splice(0, 1)[0];
      let found = false;
      for (const sv of scopedVarsPtr["nested_scoped_vars"]) {
        if (sv["module_id"] === firstMid) {
          scopedVarsPtr = sv;
          found = true;
          break;
        }
      }
      if (!found) {
        throw Error(`${firstMid} was not found in the available path`);
      }
    }
    return scopedVarsPtr["exported_variables"][varRef.variableName];
  };

  // Save our metadata with some delay to not overwhelm the server with updates
  useEffect(() => {
    // no updates for non read/roles
    if (!takerPermissionState.isReadWrite) {
      return
    }

    if (updatingMetadataJob) {
      clearTimeout(updatingMetadataJob)
    }
    let updateJob = setTimeout(() => {
      if (takerName) {
        updateTaker({
          id: originalTaker.id,
          name: takerName,
          description: originalTaker.description
        });
      }
    }, METADATA_SAVE_DELAY);
    setUpdatingMetadataJob(updateJob);
    return () => clearTimeout(updateJob);
  }, [takerName]);

  const lastSavedTimestamp = useMemo(
    () => !isQuestionnaireDataError && (lastMarkedClean || latestQuestionnaireData?.updatedAt),
    [
      isQuestionnaireDataError,
      lastMarkedClean,
      latestQuestionnaireData
    ]
  );

  return (
    <Context.Provider
      value={{
        // raw data for consumer
        taker: originalTaker,
        activeTargetTakerState: activeTargetTakerState.current,
        takerDocumentUploads: takerDocumentUploadsState,
        refetchTakerDocument,

        // Data state, for loading and initializing page
        isSaving: updateTakerResult.isLoading,
        initTakerStateCount: initTakerStateCount,
        isTakerDocumentUninitialized: isTakerDocumentUninitialized,
        isQuestionnaireUninitialized: isQuestionnaireDataUninitialized || isTakerDocumentUninitialized,
        isTakerDocumentLoading,

        lastSavedTimestamp,
        takerDocumentId,
        latestQuestionnaireDataId: latestQuestionnaireData?.id,
        latestQuestionnaireData,

        // TODO: this may not always update

        activeTakerDocument,

        latestContentFilterAnalyses,
        latestExecSummaryAnalyses,
        latestDocQAAnalyses,

        takerPermissionState,

        takerName: getTakerName(),

        updateTakerName: (n: string) => setTakerName(n),



        questionnareDataService: {
          updateAnswer(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateAnswer(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateAnswers(moduleIds, nameValuePairs, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              for (let i = 0; i < nameValuePairs.length; i++) {
                newTargetState.updateAnswer(moduleIds, nameValuePairs[i].name, nameValuePairs[i].value, iteration);
              }
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateAnalysis(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateAnalysis(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateReference(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateReference(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateSource(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateSource(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          getAnswer: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getAnswer(moduleIds, q, iteration),
          getAnalysis: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getAnalysis(moduleIds, q, iteration),
          getReference: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getReference(moduleIds, q, iteration),
          getSource: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getSource(moduleIds, q, iteration),
          getMockAnswer: (moduleIds, q, iteration) =>
            dummyTakerState.getAnswer(moduleIds, q, iteration),
          getMockAnalysis: (moduleIds, q, iteration) =>
            dummyTakerState.getAnalysis(moduleIds, q, iteration),
          getMockReference: (moduleIds, q, iteration) =>
            dummyTakerState.getReference(moduleIds, q, iteration),
          getMockSource: (moduleIds, q, iteration) =>
            dummyTakerState.getSource(moduleIds, q, iteration),
          addIterations(moduleIds, n) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              for (let i = 0; i < n; i++) {
                newTargetState.addIteration(moduleIds);
              }
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          removeIteration(moduleIds, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.removeIteration(moduleIds, iteration);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          count: (moduleIds) =>
            activeTargetTakerState.current && activeTargetTakerState.current.count(moduleIds)
        } as QuestionnaireDataService,

        takerOutput: takerDocumentStateV2,

        // fulfillment state should be undefined until the API returns a response
        fulfillmentStateHolder: isTakerDocumentStateV2Success &&
          takerDocumentStateV2['fulfillment_state'] &&
          new FulfillmentStateHolder(takerDocumentStateV2['fulfillment_state']),

        isTakerStateDirty: isStateDirty,

        createSummaryAnalyses,
        createSingleSummary,
        createContentFilteringAnalyses,

        getVariableValue: (mids: string[], varRef: VariableReference) =>
          getVariableValue(mids, varRef, takerDocumentStateV2),

        getActiveSetting,

        commentsOpen,
        setCommentsOpen,
        reviewComments,
        addReviewComment,
        updateReviewComment
      }}>
      {children}
    </Context.Provider>
  );
};

export default memo(TakerDocumentContainer);