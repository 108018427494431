import React, { useState, useMemo, ReactElement, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
} from "@mui/material/";

interface DialogStep {
    stepId: string;
    stepElement: ReactElement;
}

interface MultiStepDialogProps {
    defaultShowDialog: boolean;
    allDialogSteps: DialogStep[];
    modalTitle: ((ds: string | null) => string) | string;
    onCompleteDialog: () => void;
    onChangeShowDialog: (s: boolean) => void;
}

export const MultiStepDialog = ({
    defaultShowDialog,
    allDialogSteps,
    modalTitle,
    onCompleteDialog,
    onChangeShowDialog
}: MultiStepDialogProps) => {
    const [dialogState, setDialogState] = useState<string | null>(null);
    const [showDialog, setShowDialog] = useState<boolean>(defaultShowDialog);

    useEffect(() => {
        onChangeShowDialog(showDialog);
    }, [showDialog]);

    const finalModalTitle: string = useMemo(() => {
        if (typeof modalTitle === "string") {
            return modalTitle;
        }
        return modalTitle(dialogState);
    }, [modalTitle, dialogState]);

    const firstDialogState: string = useMemo(() => allDialogSteps[0].stepId, [allDialogSteps]);

    const isLastDialogState: boolean = useMemo(
        () => allDialogSteps[allDialogSteps.length - 1].stepId === dialogState,
        [allDialogSteps, dialogState]
    );

    useEffect(() => {
        setShowDialog(defaultShowDialog);
        if (defaultShowDialog) {
            setDialogState(firstDialogState);
        }
    }, [defaultShowDialog, firstDialogState]);

    const nextDialogState: string | null = useMemo(() => {
        if (isLastDialogState) {
            return null;
        }

        let i = allDialogSteps.findIndex((ds) => ds.stepId === dialogState);
        if (i !== -1) {
            return allDialogSteps[i + 1].stepId;
        }
        return null;
    }, [isLastDialogState, allDialogSteps, dialogState]);

    const activeStepElement = useMemo(() => {
        let activeStepElements = allDialogSteps
            .filter(dialogStep => dialogStep.stepId === dialogState)
            .map(dialogStep => (dialogStep.stepElement));

        if (activeStepElements.length === 0) {
            return (
                <CircularProgress />
            );
        }
        return activeStepElements[0];
    }, [isLastDialogState, allDialogSteps, dialogState]);

    return (
        <Dialog
            open={showDialog}
            onClose={() => {
                setDialogState("config");
                setShowDialog(false);
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {finalModalTitle}
            </DialogTitle>
            <DialogContent>
                {activeStepElement}
            </DialogContent>
            <DialogActions>
                {isLastDialogState ? (
                    <>
                        <Button
                            data-testid="submit-button"
                            onClick={() => {
                                onCompleteDialog();
                                setShowDialog(false);
                                setDialogState(null);
                            }}
                            autoFocus
                        >
                            Submit
                        </Button>
                        <Button
                            data-testid="cancel-button"
                            onClick={() => {
                                setShowDialog(false);
                                setDialogState(null);
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            onClick={() => {
                                setDialogState(nextDialogState);
                            }}
                            autoFocus
                        >
                            Continue
                        </Button>
                        <Button
                            onClick={() => {
                                setShowDialog(false);
                                setDialogState(null);
                            }
                            }>
                            Cancel
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}