import { DocumentAnnotation, DocumentKeyTerms, KeyTerm, LexicalDocument } from "./taker/documentkeyterms.generated";
import jp from "jsonpath";

export interface DocumentKeyTermsMutation {
    type: "add-to-array" | "remove-from-array" | "update-object" | "array-swap";
    jsonPath: string;
    value: any;
}

export class DocumentKeyTermsHolder {
    documentKeyTerms: DocumentKeyTerms;
    mutations: DocumentKeyTermsMutation[];

    constructor(documentKeyTerms: Object) {
        this.documentKeyTerms = JSON.parse(JSON.stringify(documentKeyTerms)) as DocumentKeyTerms;
        this.mutations = []
    }

    _applyMutation(mutation: DocumentKeyTermsMutation) {
        switch (mutation.type) {
            case "add-to-array":
                jp.apply(this.documentKeyTerms, mutation.jsonPath, (v) => {
                    v.push(mutation.value);
                    return v;
                });
                break;
            case "remove-from-array":
                jp.apply(this.documentKeyTerms, mutation.jsonPath, (v) => {                    
                    return v.filter((item: any) => 
                        !Object.entries(mutation.value).every(([key, value]) => item[key] === value)
                    );
                });
                break;
            case "array-swap":
                jp.apply(this.documentKeyTerms, mutation.jsonPath, (v) => {  
                    const temp = v[mutation.value[0]];
                    v[mutation.value[0]] = v[mutation.value[1]];
                    v[mutation.value[1]] = temp;              
                    return v;
                });
                break;
            case "update-object":
                jp.apply(this.documentKeyTerms, mutation.jsonPath, (v) => {
                    Object.assign(v, mutation.value);
                    return v;
                });
                break;
        }
        this.mutations.push(mutation);
    }
    
    createKeyTerm(keyTerm: KeyTerm) {
        // added a key term
        this._applyMutation({
            type: "add-to-array",
            jsonPath: jp.stringify(["$", "keyTerms"]),
            value: keyTerm
        });
    }

    createNewKeyTerm(termName: string) {
        if (!this.containsKeyTerm(termName)) {
            this.createKeyTerm({
                identifier: window.crypto.randomUUID(),
                categories: [],
                documentAnnotations: [],
                summary: "",
                termName,
                locked: false,
                lastUpdatedSummaryAt: new Date().getTime()
            });
        }
    }

    containsKeyTerm(termName: string) {
        return this.documentKeyTerms.keyTerms.some((keyTerm: KeyTerm) => keyTerm.termName == termName);
    }

    removeKeyTerm(termName: string) {
        this._applyMutation({
            type: "remove-from-array",
            jsonPath: jp.stringify(["$", "keyTerms"]),
            value: {
                termName: termName
            }
        });
    }

    removeAllKeyTerms() {
        for (const keyTerm of this.documentKeyTerms.keyTerms) {
            this._applyMutation({
                type: "remove-from-array",
                jsonPath: jp.stringify(["$", "keyTerms"]),
                value: {
                    termName: keyTerm.termName
                }
            });
        }
    }

    updateKeyTermInfo(identifier: string, newTermName: string, newCategories: string[]) {
        let idx = this.documentKeyTerms.keyTerms.findIndex(kt => kt.identifier === identifier);
        this._applyMutation({
            type: "update-object",
            jsonPath: jp.stringify(['$', 'keyTerms', idx]),
            value: {
                termName: newTermName,
                categories: newCategories,
                lastUpdatedSummaryAt: new Date().getTime()
            }
        });
    }

    updateKeyTermInfoAndSummary(identifier: string, newTermName: string, newCategories: string[], newSummary: string) {
        let idx = this.documentKeyTerms.keyTerms.findIndex(kt => kt.identifier === identifier);
        this._applyMutation({
            type: "update-object",
            jsonPath: jp.stringify(['$', 'keyTerms', idx]),
            value: {
                termName: newTermName,
                categories: newCategories,
                summary: newSummary,
                lastUpdatedSummaryAt: new Date().getTime()
            }
        });
    }

    updateKeyTermSummary(identifier: string, newSummary: string) {
        let idx = this.documentKeyTerms.keyTerms.findIndex(kt => kt.identifier === identifier);
        this._applyMutation({
            type: "update-object",
            jsonPath: jp.stringify(['$', 'keyTerms', idx]),
            value: {
                summary: newSummary,
                lastUpdatedSummaryAt: new Date().getTime()
            }
        });
    }

    updateKeyTermPosition(identifier: string, shift: number) {
        let keyTermSourceIndex = this.documentKeyTerms.keyTerms.findIndex(kt => kt.identifier === identifier);
        let keyTermTargetIndex = keyTermSourceIndex + shift;
        this._applyMutation({
            type: "array-swap",
            jsonPath: jp.stringify(['$', 'keyTerms']),
            value: [keyTermSourceIndex, keyTermTargetIndex]
        });
    }

    addDocumentAnnotation(termNames: string[], docAnnotation: DocumentAnnotation) {
        let termNamesCpy = new Set(termNames);
        for (let i = 0; i < this.documentKeyTerms.keyTerms.length; i++) {
            const kt = this.documentKeyTerms.keyTerms[i];
            if (termNames.includes(kt.termName)) {
                this._applyMutation({
                    type: "update-object",
                    jsonPath: jp.stringify(["$", "keyTerms", i]),
                    value: {
                        lastUpdatedDocumentReferencesAt: new Date().getTime()
                    }
                });
                this._applyMutation({
                    type: "add-to-array",
                    jsonPath: jp.stringify(["$", "keyTerms", i, "documentAnnotations"]),
                    value: { ...docAnnotation }
                });
                termNamesCpy.delete(kt.termName);
            }
        }

        // Add new terms
        for (const newTermName of termNamesCpy) {
            this.createKeyTerm({
                identifier: window.crypto.randomUUID(),
                categories: [],
                documentAnnotations: [{ ...docAnnotation }],
                summary: "",
                termName: newTermName,
                locked: false,
                lastUpdatedSummaryAt: new Date().getTime()
            });
        }
    }

    removeDocumentAnnotation(termName: string, documentAnnotation: DocumentAnnotation) {
        for (let i = 0; i < this.documentKeyTerms.keyTerms.length; i++) {
            const kt = this.documentKeyTerms.keyTerms[i];
            if (termName === kt.termName 
                && kt.documentAnnotations
                && kt.documentAnnotations.some(da => da.annotationId === documentAnnotation.annotationId)
            ) {
                this._applyMutation({
                    type: "remove-from-array",
                    jsonPath: jp.stringify(["$", "keyTerms", i, "documentAnnotations"]),
                    value: {
                        annotationId: documentAnnotation.annotationId
                    }
                });
                this._applyMutation({
                    type: "update-object",
                    jsonPath: jp.stringify(["$", "keyTerms", i]),
                    value: {
                        lastUpdatedSummaryAt: new Date().getTime()
                    }
                });
            }
        }
    }

    createLexicalDocument(ld: LexicalDocument) {
        this._applyMutation({
            type: "add-to-array",
            jsonPath: jp.stringify(["$", "lexicalDocuments"]),
            value: ld
        });
    }

    removeLexicalDocument(documentIdentifier: string) {
        if (this.documentKeyTerms.lexicalDocuments) {
            // Remove all annotations associated with document
            for (let i = 0; i < this.documentKeyTerms.keyTerms.length; i++) {
                const kt = this.documentKeyTerms.keyTerms[i];
                if (kt.documentAnnotations && 
                    kt.documentAnnotations.some(da => da.lexicalDocumentIdentifier === documentIdentifier)
                ) {
                    this._applyMutation({
                        type: "remove-from-array",
                        jsonPath: jp.stringify(["$", "keyTerms", i, "documentAnnotations"]),
                        value: {
                            lexicalDocumentIdentifier: documentIdentifier
                        }
                    });
                }
            }

            // Remove the whole document.
            this._applyMutation({
                type: "remove-from-array",
                jsonPath: jp.stringify(["$", "lexicalDocuments"]),
                value: {
                    identifier: documentIdentifier
                }
            });
        }
    }

    buildIndexedDocumentAnnotations() {
        const indexed: Record<string, Record<number, DocumentAnnotation[]>> = {};
        for (const kt of this.documentKeyTerms.keyTerms) {
            if (kt.documentAnnotations) {
                for (const da of kt.documentAnnotations) {
                    if (!indexed[da.lexicalDocumentIdentifier]) {
                        indexed[da.lexicalDocumentIdentifier] = {};
                    }
                    if (!indexed[da.lexicalDocumentIdentifier][da.page]) {
                        indexed[da.lexicalDocumentIdentifier][da.page] = [];
                    }
                    indexed[da.lexicalDocumentIdentifier][da.page].push(da);
                }
            }
        }
        return indexed;
    }
}