import SocketIO from 'socket.io-client';

const URL = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.WS_ENDPOINT : "http://localhost:8083"
const FB_TOKEN_KEY = "fb-token";

const socket = SocketIO(URL, {
    autoConnect: false,
    auth: (cb) => {
        cb({
            token: localStorage.getItem(FB_TOKEN_KEY)
        });
    }
});

export default socket;